/**
 * App entry point. Configures and initializes Vue
 */

import 'overlayscrollbars/css/OverlayScrollbars.css';
import Vue from 'vue';
import App from '@/App.vue';
import VueRouter from 'vue-router';
import VueTour from 'vue-tour';
import store from './vuex';
import VueMeta from 'vue-meta';
import { vuetify, auth, router } from '@/plugins';
import { StripePlugin } from '@vue-stripe/vue-stripe';
import { Plugin as fragment } from 'vue-fragment';
import { OverlayScrollbarsPlugin } from 'overlayscrollbars-vue';
import { PK } from '@/data/var.config';
import { createSimpleTransition } from 'vuetify/lib/components/transitions/createTransition';
import VueGtag from 'vue-gtag';

Vue.config.productionTip = false;

Vue.use(VueRouter);
Vue.use(fragment);
Vue.use(OverlayScrollbarsPlugin);
require('vue-tour/dist/vue-tour.css');

Vue.use(VueTour);

Vue.use(VueMeta);
Vue.use(auth);
const options = {
  pk: PK,
};
Vue.use(StripePlugin, options);

const myTransition = createSimpleTransition('my-transition');

Vue.component('my-transition', myTransition);

Vue.use(
  VueGtag,
  {
    config: { id: 'UA-173540556-1' },
  },
  router
);

const app = new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
  created() {
    // Vue.use(auth, this.$store);
    this.$auth.$store = this.$store;
  },
});

store.$app = app;

app.$mount('#app');

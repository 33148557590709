<!--
  Shows Spinner representing Upload Progress
  Used by UploadOverlay.vue
-->

<template>
  <div class="progressContainer">
    <v-progress-circular size="100" :value="progress"></v-progress-circular>
    <p style="margin-top: 50px">Opening file...</p>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { UploadKeys as UK } from '@/data/constants';

export default {
  name: 'UploadProgress',
  computed: {
    ...mapState('upload', {
      uploading: (state) => state[UK.UPLOADING],
      progress: (state) => state[UK.PROGRESS],
    }),
  },
};
</script>

<style lang="scss" scoped>
.progressContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>

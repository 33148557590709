import store from '@/vuex';
import { DraftKeys as dk } from '@/data/constants';

const placeholder = 'PLACEHOLDER';
const scrollSpeedInMS = 500;
const charCodeStart = 97;

/**
 * Update the figure numbers in the figure editor and update the corresponding labels in the specification
 * @param {Object} fig - Figure that was changed
 * @return {Promise<String>} The updated specification
 */
export async function updateFigureNumbers(fig) {
  const figs = _getFigures();
  let spec = _getSpec();

  const overflow = _checkIfOverflow(fig);
  const { conflict, index } = _checkForConflicts(fig, figs);
  const { overflowGroup, overflowUpdateChain } = _findOverflowGroup(fig, figs, overflow);

  spec = _fixOverflowConflicts(overflowGroup, overflowUpdateChain, figs, fig, spec);
  spec = conflict ? _fixConflict(figs, fig, index, spec, overflowGroup) : _updateLabels(fig, spec);
  spec = overflow ? _removeOverflowPlaceholders(overflowGroup, figs, spec) : spec;

  await _sortFigs(figs);

  const newIndex = figs.findIndex((f) => f.id === fig.id);

  if (fig.oldIndex !== newIndex) {
    _scrollToNewPosition(fig);
  }

  return spec;
}

export async function changeBlockDiagramToIllustration(figNumber) {
  let spec = _getSpec();
  const blockReg = _createBlockDiagramRegex(figNumber);
  const blockThatReg = _createBlockBlockDiagramThatRegex(figNumber);
  const blockOverflowReg = _createBlockDiagramOverflowRegex(figNumber);
  spec = await spec
    .replace(blockReg, 'illustrates')
    .replace(blockThatReg, '')
    .replace(blockOverflowReg, 'further illustrates');

  return spec;
}

// --- Internal functions ---

function _updateLabels(fig, spec) {
  spec = _replacePartLabels({ fig, spec });
  spec = _replaceFigLabels({ fig, spec });
  return spec;
}

function _fixConflict(figs, fig, index, spec, overflowGroup) {
  spec = _insertPlaceholders(spec, fig);
  spec = _fixConflictsInFigures(figs, fig, index, spec, overflowGroup);
  spec = _removePlaceholders(spec, fig);
  return spec;
}

function _removeOverflowPlaceholders(group, figs, spec) {
  for (let i = group.start, l = group.end; i <= l; i++) {
    const currentFigure = figs[i];
    const oldVal = _createOverflowFigPlaceholder(i);
    spec = _removePlaceholders(spec, currentFigure, oldVal);
  }

  return spec;
}

function _scrollToNewPosition(fig) {
  const editorRef = _getEditorRef();
  const $fig = document.getElementById(fig.id);

  editorRef.scroll($fig, scrollSpeedInMS);
}

function _findOverflowGroup(fig, figs, overflow) {
  if (overflow) {
    const overflowGroup = _findOverFlowGroup(fig, figs);
    const { conflict, index } = _checkForConflicts(fig, figs, overflow);

    const overflowUpdateChain = conflict ? _findUpdateChain(figs, fig, index, overflow) : null;

    return { overflowGroup, overflowUpdateChain };
  }

  return { overflowGroup: null, overflowUpdateChain: null };
}

function _fixOverflowConflicts(overflowGroup, updateChain, figs, fig, spec) {
  for (let i = overflowGroup?.end, l = overflowGroup?.start; i >= l; i--) {
    const curFig = figs[i];
    const letter = _getFigureLetter(curFig.number);
    const newNumber = parseInt(fig.number);

    if (curFig.id !== fig.id) {
      const newVal = _createOverflowFigPlaceholder(i);
      spec = _insertPlaceholders(spec, { oldNumber: curFig.number }, newVal);

      if (updateChain?.start <= i && i <= updateChain?.end) {
        _updateFigureLetter(curFig, letter);
      }

      _updateFigure(curFig, newNumber);
    }
  }

  return spec;
}

function _createOverflowFigPlaceholder(index) {
  const p = `${placeholder}${String.fromCharCode(index + charCodeStart)}`;
  return p.toUpperCase();
}

function _checkForConflicts(fig, figs, overflow = false) {
  for (let i = 0, l = figs.length; i < l; i++) {
    const currentFig = figs[i];
    const currentInt = parseInt(currentFig.number);
    const focusedInt = parseInt(fig.number);
    const oldInt = parseInt(fig.oldNumber);
    const letter = _getFigureLetter(currentFig.number);
    const focusedLetter = _getFigureLetter(fig.number);

    if (overflow && currentInt === oldInt && letter === focusedLetter && currentFig.id !== fig.id) {
      // There is a conflict in overflow figures if they both have the same letter in their figure number.
      return { conflict: true, index: i };
    }
    if (
      !overflow &&
      currentInt === focusedInt &&
      currentFig.id !== fig.id &&
      currentInt !== oldInt
    ) {
      // There is a conflict if 2 different figures have the same figure number
      return { conflict: true, index: i };
    }
  }

  // No conflict detected
  return { conflict: false, index: -1 };
}

function _checkIfOverflow(fig) {
  return fig.overflow;
}

function _updateFigureLetter(fig, letter) {
  const charCode = _getCharCode(letter) + 1;
  const newLetter = String.fromCharCode(charCode);
  const number = parseInt(fig.number);
  const newNumber = `${number}${newLetter}`;
  fig.number = newNumber.toUpperCase();
}

function _fixConflictsInFigures(figs, fig, index, spec, overflow) {
  const updateChain = _findUpdateChain(figs, fig, index);

  return _incrementFigureNumbers(updateChain, figs, spec, parseInt(fig.oldNumber), overflow);
}

function _replacePartLabels({ fig, spec, oldVal = null, newVal = null }) {
  if (!newVal) newVal = parseInt(fig.number);
  if (!oldVal) oldVal = parseInt(fig.oldNumber);

  const regex = _createPartLabelRegex(oldVal);

  return spec.replace(regex, newVal);
}

function _replaceFigLabels({ fig, spec, oldVal = fig.oldNumber, newVal = fig.number }) {
  const regex = _createFigLabelRegex(oldVal);

  return spec.replace(regex, `$1 ${newVal}`);
}

function _findOverFlowGroup(fig, figs) {
  const index = fig.oldIndex;

  const start = _findOverflowStart({ fig, figs, start: index });
  const end = _findOverflowEnd({ fig, figs, start: index });

  return { start, end };
}

function _getFigureLetter(number) {
  const char = number.charAt(number.length - 1);
  if (char.toLowerCase().charCodeAt(0) < charCodeStart) {
    return String.fromCharCode(charCodeStart - 1).toUpperCase();
  }
  return char;
}

function _findUpdateChain(figs, fig, index, overflow = false) {
  const newCharCode = _getCharCode(fig.number);
  const oldCharCode = _getCharCode(fig.oldNumber);
  const newNumber = parseInt(fig.number);
  const oldNumber = parseInt(fig.oldNumber);

  let start = 0;
  let end = 0;

  if ((overflow && newCharCode < oldCharCode) || (!overflow && newNumber < oldNumber)) {
    start = index;
    end = _findEnd({ figs, start, end: fig.oldIndex - 1, overflow });
  }

  if ((overflow && newCharCode > oldCharCode) || (!overflow && newNumber > oldNumber)) {
    start = index;
    end = _findEnd({ figs, start, overflow });
  }

  return { start, end };
}

function _incrementFigureNumbers(updateChain, figs, spec, oldFigNumber, overflow) {
  for (let i = updateChain.end, l = updateChain.start; i >= l; i--) {
    const currentFig = figs[i];
    const currentNum = currentFig.number;
    const inOverflow = i >= overflow?.start && i <= overflow?.end;
    if (currentNum !== oldFigNumber && !inOverflow) {
      const updatedFig = _updateFigure(currentFig);
      spec = _replacePartLabels({
        fig: { number: updatedFig.number, oldNumber: currentNum },
        spec,
      });
      spec = _replaceFigLabels({ fig: { number: updatedFig.number, oldNumber: currentNum }, spec });
    }
  }

  return spec;
}

function _sortFigs(figs) {
  figs.sort((a, b) => {
    const nameA = a.number.toUpperCase();
    const nameB = b.number.toUpperCase();

    const intA = parseInt(a.number);
    const intB = parseInt(b.number);

    if (intA === intB) {
      if (nameA < nameB) {
        return -1;
      }

      if (nameA > nameB) {
        return 1;
      }
      return 0;
    } else {
      return intA - intB;
    }
  });
}

function _findOverflowEnd({ fig, figs, start = 0, end = figs.length - 1 }) {
  for (let i = start, l = end; i < l; i++) {
    const currentFig = figs[i];
    const nextFig = figs[i + 1];
    const number = parseInt(currentFig.number);
    const nextNumber = nextFig ? parseInt(nextFig.number) : null;
    const oldNumber = parseInt(fig.oldNumber);

    if (fig.id === currentFig?.id) {
      if (oldNumber !== nextNumber) {
        return i;
      }
    } else if (number !== nextNumber && nextFig?.id !== fig.id) {
      return i;
    }
  }

  return end;
}

function _findOverflowStart({ fig, figs, start = figs.length - 1, end = 0 }) {
  for (let i = start, l = end; i >= l; i--) {
    const currentFig = figs[i];
    const nextFig = figs[i - 1];
    const number = parseInt(currentFig.number);
    const nextNumber = nextFig ? parseInt(nextFig.number) : null;
    const oldNumber = parseInt(fig.oldNumber);

    if (fig.id === currentFig?.id) {
      if (oldNumber !== nextNumber) {
        return i;
      }
    } else if (number !== nextNumber && nextFig?.id !== fig.id) {
      return i;
    }
  }

  return 0;
}

function _findEnd({ figs, start, end = figs.length - 1, overflow = false }) {
  for (let i = start, l = end; i <= l; i++) {
    const currentFig = figs[i];
    const nextFig = figs[i + 1];
    const currentVal = overflow ? _getCharCode(currentFig.number) : parseInt(currentFig.number);
    const nextVal = nextFig
      ? overflow
        ? _getCharCode(nextFig.number)
        : parseInt(nextFig.number)
      : null;

    if (currentVal + 1 !== nextVal && currentVal !== nextVal) {
      return i;
    }
  }

  return end;
}

function _updateFigure(fig, newNumber) {
  newNumber = newNumber ? newNumber : parseInt(fig.number) + 1;
  const num = parseInt(fig.number);
  fig.number = fig.number.replace(num, newNumber);

  _updateFigureBlocks(fig.blocks, num, newNumber);

  return fig;
}

function _updateFigureBlocks(blocks, oldVal, newVal) {
  blocks.forEach((block) => (block.label = block.label.replace(oldVal, newVal)));
}

function _insertPlaceholders(spec, fig, newVal = placeholder) {
  spec = _replacePartLabels({ fig, spec, newVal });
  spec = _replaceFigLabels({ fig, spec, newVal });
  return spec;
}

function _removePlaceholders(spec, fig, oldVal = placeholder) {
  spec = _replacePartLabels({ fig, spec, oldVal });
  spec = _replaceFigLabels({ fig, spec, oldVal });
  return spec;
}

function _createBlockDiagramRegex(val) {
  return new RegExp(`(?<=fig(?:\\.|ure)? ${val}\\s)is a block diagram illustrating`, 'gi');
}

function _createBlockBlockDiagramThatRegex(val) {
  return new RegExp(`(?<=fig(?:\\.|ure)? ${val}\\s)is a block diagram that`, 'gi');
}

function _createBlockDiagramOverflowRegex(val) {
  return new RegExp(`(?<=fig(?:\\.|ure)? ${val}\\s)is a block diagram further illustrating`, 'gi');
}

function _createFigLabelRegex(val) {
  return new RegExp(`\\b(fig(?:\\.|ure)?)\\s?${val}\\b`, 'gi');
}

function _createPartLabelRegex(val) {
  return new RegExp(`\\b${val}(?=\\d\\d[a-z]?\\b)`, 'gi');
}

function _getCharCode(str, index = str.length - 1) {
  const charCode = str.toLowerCase().charCodeAt(index);
  if (charCode < charCodeStart) {
    return charCodeStart - 1;
  }
  return charCode;
}

function _getFigures() {
  return store.state.draft[dk.DIAGRAMS];
}

function _getSpec() {
  return store.state.draft[dk.DESCRIPTION];
}

function _getEditorRef() {
  return store.state.draft[dk.DIAGRAM_EDITOR_REF];
}

/**
 * Description formatters
 *
 * DEFINITIONS:
 *    Document - Document string received from Alice
 *    Description - Formatted HTML string used by the description editor
 */

import { PMLTags as PML } from '@/data/constants';

const title = '<h1>';
const titleEnd = '</h1>';
const subHeader = '<h2>';
const subHeaderEnd = '</h2>';
const paragraph = '<p>';
const paragraphEnd = '</p>';

/**
 * Formats the current paragraph depending on server tags
 *
 * @param {String} description - Accumulator message. Each iteration adds more text to this string
 * @param {String} paragraph - Current paragraph in the description
 * @return {String} - Updated description message
 */
export function formatDescription(description, paragraph) {
  paragraph = paragraph
    .replace(/<s/gi, '<span')
    .replace(/<\/s>/gi, '</span>')
    .replace(/id=/gi, 'class=');
  if (isTitle(paragraph)) {
    description += formatTitle(paragraph);
  } else if (isSubHeader(paragraph)) {
    description += formatSubHeader(paragraph);
  } else {
    description += formatParagraph(paragraph);
  }

  return description;
}

export function formatPara(paragraph) {
  paragraph = paragraph
    .replace(/<s/gi, '<span')
    .replace(/<\/s>/gi, '</span>')
    .replace(/id=/gi, 'class=');
  if (isTitle(paragraph)) {
    return formatTitle(paragraph);
  }
  if (isSubHeader(paragraph)) {
    return formatSubHeader(paragraph);
  }
  return formatParagraph(paragraph);
}

// START CHECKERS REGION

/**
 * Check if provided string is a document title
 *
 * @param text - Text to inspect
 * @return {Boolean} - True if text contains a description title tag, otherwise false.
 */
function isTitle(text) {
  return text.includes(PML.TITLE);
}

/**
 * Check if provided string is a document subheader
 *
 * @param text - Text to inspect
 * @return {Boolean} - True if text contains a document subheader tag, otherwise false
 */
function isSubHeader(text) {
  return text.includes(PML.HEADING);
}

// END CHECKERS REGION

// START FORMATTERS REGION

/**
 * Format a provided string into a description paragraph
 *
 * @param text - Text to be formatted into a paragraph
 * @return {string} - The formatted paragraph
 */
function formatParagraph(text) {
  return `${paragraph}${text}${paragraphEnd}`;
}

/**
 * Format provided string into a description title
 *
 * @param text - String to be formatted
 * @return {string} = Title string
 */
function formatTitle(text) {
  const titleTags = new RegExp(`${PML.TITLE}|${PML.TITLE_END}`);
  const span = text.replace(titleTags, '').toUpperCase();
  return `${title}${span}${titleEnd}`;
}

/**
 * Format provided string into a description subheader
 *
 * @param text - String to be formatted
 * @return {string} - Subheader string
 */
function formatSubHeader(text) {
  const headerTags = new RegExp(`${PML.HEADING}|${PML.HEADING_END}`);
  const span = text.replace(headerTags, '');
  return `${subHeader}${span}${subHeaderEnd}`;
}

// END FORMATTERS REGION

import state from './state';
import mutations from './mutations';

export default {
  namespaced: true,
  state,
  mutations,
  //getters - uncomment to add getters if needed
  //actions - uncomment to add actions if needed
};

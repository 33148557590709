<!--
  This is the main dropdown menu seen in to the top toolbar. User can navigate to
  landing page, start a new document, or upload a Word document.
-->

<template>
  <Tooltip :message="MENU_BUTTON_MESSAGE" :id="MENU_TOOLTIP_ID" bottom left-edge>
    <template #activator="tooltip">
      <v-menu
        transition="slide-y-transition"
        dark
        offset-y
        bottom
        nudge-left="300"
        content-class="remove-left-margin patentpal-menu"
        rounded="t-0"
      >
        <template #activator="menu">
          <v-tooltip
            bottom
            transition="none"
            nudge-top="10"
            nudge-left="12"
            content-class="patentpal-tooltip remove-left-margin top-corners"
          >
            <template #activator="tooltip2">
              <v-btn
                icon
                v-bind="{ ...menu.attrs, ...tooltip.attrs, ...tooltip2.attrs }"
                v-on="{ ...menu.on, ...tooltip2.on }"
                id="dropdownMenuButton"
              >
                <v-app-bar-nav-icon />
              </v-btn>
            </template>
            <span>Menu</span>
          </v-tooltip>
        </template>
        <v-list>
          <!-- NEW DOCUMENT DIALOG-->
          <v-list-item
            v-if="showGenerate"
            @click="newDocument"
            :disabled="claims.length === 0 && initialOutline"
          >
            <v-list-item-icon
              ><v-icon :color="claims.length === 0 && initialOutline ? 'grey' : 'default'"
                >mdi-plus</v-icon
              ></v-list-item-icon
            >
            <v-list-item-title>{{ MENU_NEW_DOC }}</v-list-item-title>
          </v-list-item>
          <!-- END NEW DOCUMENT DIALOG -->

          <!-- UPLOAD WORD DOCUMENT DIALOG -->
          <v-list-item
            v-if="showGenerate"
            @click="
              triggerSelection((e) => {
                saveFile({ e, action });
              })
            "
          >
            <v-list-item-icon><v-icon>mdi-upload</v-icon></v-list-item-icon>
            <v-list-item-title>{{ MENU_UPLOAD }}</v-list-item-title>
          </v-list-item>
          <!-- END UPLOAD WORD DOCUMENT DIALOG -->

          <v-list-item @click="startTutorial">
            <v-list-item-icon><v-icon> mdi-help </v-icon> </v-list-item-icon>
            <v-list-item-title>Help</v-list-item-title>
          </v-list-item>

          <v-list-item
            href="https://www.youtube.com/channel/UCiGGnpAhMI7P6jeAgmo1mjw"
            target="_blank"
          >
            <v-list-item-icon><v-icon>mdi-youtube</v-icon></v-list-item-icon>
            <v-list-item-title>Tutorials</v-list-item-title>
          </v-list-item>

          <!-- LINK TO LANDING PAGE -->
          <v-list-item @click="goHome">
            <v-list-item-icon><v-icon>mdi-home</v-icon></v-list-item-icon>
            <v-list-item-title>{{ MENU_BACK }}</v-list-item-title>
          </v-list-item>
          <!-- END LINK TO LANDING PAGE -->
        </v-list>
      </v-menu>
    </template>
  </Tooltip>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import { MENU_BACK, MENU_UPLOAD, MENU_NEW_DOC } from '@/data/constants/constantsMessages';
import { DraftKeys as dk } from '@/data/constants';
import {
  ACTION_NEW_DOCUMENT,
  CATEGORY_DRAFT,
  ACTION_MAIN_MENU_SELECT_UPLOAD,
  CATEGORY_TUTORIAL,
  ACTION_TUTORIAL_START,
} from '@/data/constants/constantsGoogleAnalyticsEvents';
import Tooltip from '@/components/global/Tooltip';
import { MENU_BUTTON_MESSAGE, MENU_TOOLTIP_ID } from '@/data/constants/constantsTooltip';

export default {
  name: 'MainMenu',
  components: { Tooltip },
  data() {
    return {
      slide: null,
      show: false,
      MENU_BACK,
      MENU_UPLOAD,
      MENU_NEW_DOC,
      action: ACTION_MAIN_MENU_SELECT_UPLOAD,
      MENU_BUTTON_MESSAGE,
      MENU_TOOLTIP_ID,
    };
  },
  computed: {
    ...mapState({
      claims: (state) => state.draft[dk.CLAIMS],
      step: (state) => state.draft[dk.STEP],
      showTooltip: (state) => state.tooltip.showTooltip,
      /**
       * @returns {Boolean}
       */
    }),
    ...mapGetters('draft', { initialOutline: 'initialOutline', showGenerate: 'showGenerate' }),
  },
  methods: {
    ...mapMutations({
      resetState: 'draft/resetState',
      setDropUpload: 'draft/setDropUpload',
      setUploadDialog: 'draft/setUploadDialog',
      setOverwrite: 'draft/setOverwrite',
      toggleDialog: 'upload/toggleDialog',
      triggerSelection: 'upload/triggerSelection',
      saveFile: 'upload/saveFile',
      saveUserContent: 'draft/saveUserContent',
      setDraftState: 'draft/setDraftState',
      setTooltipState: 'tooltip/setTooltipState',
    }),

    startTutorial() {
      this.$gtag.event(ACTION_TUTORIAL_START, { event_category: CATEGORY_TUTORIAL });
      this.setDraftState({ key: 'showX', val: true });
      this.$tours.homeTour.start();
    },

    newDocument() {
      this.$gtag.event(ACTION_NEW_DOCUMENT, { event_category: CATEGORY_DRAFT });
      this.toggleDialog();
    },

    goHome() {
      this.saveUserContent();
      window.location = 'https://patentpal.com';
    },

    startNewDocument() {
      this.dialog = false;
      this.dialog2 = false;
      this.resetState(this.step === 2);
    },

    cancel() {
      this.dialog2 = false;
      this.slide = 0;
    },

    /**
     * Uploads the file to s3 and then resets the state and sets sets the new
     * claims
     */
    async handleFileUpload(e) {
      e.preventDefault();
      this.startNewDocument();
      this.setDropUpload({ val: true, file: e.target.files[0] });

      if (e.target) e.target.value = null;

      const temp = document.getElementById('tempInput');
      if (temp) temp.remove();
    },
  },
  watch: {
    '$route.name': {
      deep: true,
      immediate: true,
      handler(val) {
        if (val === 'draft') {
          this.show = true;
        } else {
          this.show = false;
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  width: 500px !important;
}

.fileInput {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
</style>

// These are keys used in the vuex error state module.
// This was an experiment to try and reduce the amount of work
// it would take to change a key in the state object.
// In practice it has actually been difficult to maintain due to
// needing to import this anywhere we want to access state

export const ERROR = 'error';
export const WARN = 'warn';
export const MESSAGE = 'message';
export const SUCCESS = 'success';

export const CLIENT_ERROR = 400;
export const USER_ERROR = 440;
export const SERVER_ERROR = 500;
export const UPLOAD_ERROR = `Unable to open your file. If problem persists contact <a href='mailto:support@email.com'>support@patentpal.com</a>.`;
export const METHOD_CLAIMS =
  'We currently only support system claims. Support for method claims is coming soon.';

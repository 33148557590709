/**
 * Pack outline into transfer data structure
 *
 * @param {Object[]} outlines - Internal outline objects from outline editor
 * @return {Object[]} - transfer data
 */
import { SIDE_PADDING, TOP_PADDING } from '@/data/constants/constantsDiagram';

export function packOutline(outlines) {
  return outlines.reduce((packedOutlines, outline) => {
    const packedOutline = { ...outline };
    packedOutline.concepts = packedOutline.concepts.map(convertReferences);
    packedOutline.children = packedOutline.points.map((p) => p.id);
    packedOutline.parent = null;
    packedOutline.points = flatten(packedOutline.points, 'children');
    packedOutline.points.forEach(
      (point) => point.parent === null && (point.parent = packedOutline.id)
    );
    packedOutline.tags = flattenTags(packedOutline.points);
    packedOutlines.push(packedOutline);
    return packedOutlines;
  }, []);
}

export function packDiagrams(diagrams) {
  return diagrams.reduce((packDiagrams, d) => {
    const diagram = {
      id: d.id,
      label: d.number,
      overflow: d.overflow,
      illustration: d.illustration,
      lines: d.links.map((l) => {
        return {
          id: l.id,
          head: l.head,
          link: l.link,
          tail: l.tail,
          text: l.text,
          type: l.type,
        };
      }),
      blocks: d.blocks.map((b) => {
        return {
          children: b.children,
          concept: b.concepts,
          height: b.height,
          width: b.width,
          id: b.id,
          label: b.label,
          lines: b.links,
          parent: b.parent,
          text: b.text,
          border: b.border,
          shape: b.shape,
          x: b.x - SIDE_PADDING,
          y: b.y - TOP_PADDING,
        };
      }),
    };
    packDiagrams.push(diagram);
    return packDiagrams;
  }, []);
}

export function packClaims(claims) {
  const paraAndListEnds = /<\/p>|<\/li>/gi;
  const allHTMLTags = /<.+?>|<\/.+?>|&nbsp;/gi;
  return claims.replace(paraAndListEnds, '\n').replace(allHTMLTags, '');
}

/**
 * Replaces references with IDs depending on the data type received.
 *
 * @param {Object | Object[] } obj
 * @param {Object[]} [obj.tags]
 * @param {String} [obj.anchor]
 * @param {Object} [obj.concept]
 * @param {String} [pointId]
 * @return {Object | Object[]} returns the cleaned Object or Array
 */
function convertReferences(obj, pointId = null) {
  const isPoint = obj.tags !== undefined && obj.text !== undefined;
  if (isPoint) {
    const newObj = { ...obj };
    newObj.tags = newObj.tags.map((t) => convertReferences(t, newObj.id));
    delete newObj.collapsed;
    delete newObj.prev;
    delete newObj.next;
    delete newObj.links;
    return newObj;
  }

  const isConcept = obj.lead !== undefined;
  if (isConcept) {
    const newObj = { ...obj };
    newObj.refs = obj.tags;
    delete newObj.tags;
    delete newObj.lead;
    return newObj;
  }

  const isTag = pointId !== null;
  if (isTag) {
    const newObj = { ...obj };
    if (newObj.concept) newObj.concept = newObj.concept.id;
    if (newObj.head) {
      newObj.head_ref = newObj.head;
      delete newObj.head;
    }
    if (newObj.tail) {
      newObj.tail_ref = newObj.tail;
      delete newObj.tail;
    }
    newObj.point = pointId;
    return newObj;
  }

  if (Array.isArray(obj)) {
    return obj.map((item) => item.id);
  }

  return obj;
}

/**
 * Flattens a nested array
 *
 * @param {Object[]} arr - Array to flatten
 * @param key - Key on array objects that contains the nested items
 * @return {Object[]} - Flattened array
 */
function flatten(arr, key) {
  return arr.reduce((flatArr, i) => {
    const item = { ...i };
    const nested = item[key];
    if (nested) {
      const temp = flatten(nested, key);
      flatArr.push(...temp);
      item[key] = convertReferences(nested);
    }
    const cleanedItem = convertReferences(item);
    flatArr.unshift(cleanedItem);
    return flatArr;
  }, []);
}

/**
 * Specialized function for extracting tags from points into a flat array
 *
 * @param {Object[]} arr - Array of points
 * @return {Object[]} - Flattened array of tags
 */
function flattenTags(arr) {
  return arr.reduce((packedTags, point) => {
    point.tags = point.tags.map((tag) => {
      packedTags.push(tag);
      return tag.id;
    });
    return packedTags;
  }, []);
}

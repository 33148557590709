// These are keys used in the vuex draft state module.
// This was an experiment to try and reduce the amount of work
// it would take to change a key in the state object.
// In practice it has actually been difficult to maintain due to
// Needing to import this anywhere we want to access draft state

export const STEP = 'step';
export const FURTHEST_STEP = 'furthestStep';
export const CLAIMS_CHANGED = 'claimsChanged';
export const OUTLINE_CHANGED = 'outlineChanged';
export const LOADING = 'LOADING';
export const OUTLINE_UP_TO_DATE = 'outlineUpToDate';
export const GET_DIAGRAMS_FROM_CLAIMS = 'getDiagramsFromClaims';
export const TUTORIAL_OPT_OUT = 'tutorialOptOut';
export const PROFILE_OVERLAY = 'showProfileOverlay';
export const CLAIMS = 'claims';
export const OUTLINES = 'outlines';
export const SAVED_OUTLINES = 'savedOutlines';
export const SAVED_CLAIMS = 'savedClaims';
export const DIAGRAMS = 'diagrams';
export const DESCRIPTION = 'description';
export const OUTLINE_FROM_CLAIMS = 'outlineFromClaims';
export const FOCUSED_TAG = 'focusedTag';
export const DIAGRAMS_FROM_OUTLINE = 'diagramsFromOutline';
export const DESCRIPTION_EDITOR_REF = 'descriptionEditorRef';
export const CLAIMS_EDITOR_REF = 'claimsEditorRef';
export const DIAGRAM_EDITOR_REF = 'diagramEditorRef';
export const INSERTING = 'inserting';
export const HANDLED_PROMPT = 'handledPrompt';
export const SHOW_TOOLTIP = 'showTooltip';
export const HOVERING_TOOLTIP = 'hoveringTooltip';
export const SHOW_PHRASE = 'showPhrase';
export const LOGOUT_PROMPT = 'logoutPrompt';
export const SEQUENTIAL = 'sequential';
export const VIEWING_TUTORIAL = 'viewingTutorial';

import mxgraph from './mxGraphIndex.js';
import GraphListeners from './GraphListeners';
import {
  checkWillWrap,
  getMaxChars,
  truncate,
  getTextWidth,
  getImageData,
} from '@/support/utilities';
import { DiagramConstants as dc, AnalyticEventKeys as ak } from '@/data/constants';

const {
  mxUtils,
  mxGraphHandler,
  mxConstants,
  mxEvent,
  mxImage,
  mxEllipse,
  mxCellRenderer,
  mxRectangle,
} = mxgraph;

export function calculateScale() {
  const panelWidth = document.querySelector('.panel-content').clientWidth;
  return panelWidth / dc.STANDARD_WIDTH;
}

export function toUnit(num) {
  return num * dc.UNIT_SIZE;
}

export function calculateCalloutLinePoints(cell, unit, startX = 0, startY = 0, isVisio = false) {
  const height = isVisio ? 1 : cell.height;
  const center = isVisio ? startY - height / 2 : startY + height / 2;
  const bottom = isVisio ? 0 : startY + height;
  const right = isVisio ? 1 : startX + cell.width;
  const left = isVisio ? 0 : startX;
  const top = isVisio ? 1 : startY;

  if (cell.direction === 0) {
    // bottom left
    return [
      { x: left + 2 * unit, y: center }, // Starting point
      { x: left + 1 * unit, y: center }, // Middle point
      { x: left, y: bottom }, // End point
    ];
  }

  if (cell.direction === 1) {
    // bottom right
    return [
      { x: right - 2 * unit, y: center }, // Starting point
      { x: right - 1 * unit, y: center }, // Middle point
      { x: right, y: bottom }, // End point
    ];
  }

  if (cell.direction === 2) {
    // top left
    return [
      { x: left + 2 * unit, y: center }, // Starting point
      { x: left + 1 * unit, y: center }, // Middle point
      { x: left, y: top }, // End point
    ];
  }

  if (cell.direction === 3) {
    // top right
    return [
      { x: right - 2 * unit, y: center }, // Starting point
      { x: right - 1 * unit, y: center }, // Middle point
      { x: right, y: top }, // End point
    ];
  }

  if (cell.direction === 4) {
    return [];
  }

  return [];
}

export function configGraph(graph, instance) {
  mxConstants.RECTANGLE_ROUNDING_FACTOR = 0.5;

  // mxConstants.VERTEX_SELECTION_DASHED = false;
  // mxConstants.VERTEX_SELECTION_COLOR = '#0088cf';
  // graph.cellEditor.selectText = false;

  graph.graphHandler.removeCellsFromParent = false;
  graph.graphHandler.allowLivePreview = true;
  graph.graphHandler.maxLivePreview = 10;
  graph.htmlLabels = true;
  graph.resetEdgesOnresize = true;
  graph.resetEdgesOnMove = true;
  graph.resetEdgesOnConnect = true;
  graph.vertexLabelsMovable = false;
  graph.edgeLabelsMoveable = false;
  graph.centerZoom = true;
  graph.dropEnabled = false;
  graph.swimlaneNesting = false;

  // graph.cellsMovable = false;
  // graph.cellsResizable = false;

  graph.cellsSelectable = false;
  graph.enterStopsCellEditing = true;
  graph.view.scale = calculateScale();
  graph.pageFormat = mxConstants.PAGE_FORMAT_LETTER_PORTRAIT;
  graph.autoExtend = false;
  graph.maximumGraphBounds = new mxRectangle(0, 0, dc.STANDARD_WIDTH, dc.STANDARD_HEIGHT);
  graph.setConnectable(false);
  graph.setGridEnabled(false);
  graph.setCellsDisconnectable(false);
  graph.setPanning(false);
  graph.setAllowDanglingEdges(true);
  graph.setConstrainChildren(true);
  graph.setTooltips(false);

  // This disables editing in the editor. To re-enable editing either comment out, delete this line, or change it to true
  // graph.setEnabled(false);

  // show guides when dragging blocks
  mxGraphHandler.prototype.guidesEnabled = false;
  // Prevent area from scrolling when moving blocks
  mxGraphHandler.prototype.scrollOnMove = false;

  registerCustomShapes(graph);
  addGraphListeners(graph, instance);
  setVertexStyles(graph);
  setEdgeStyles(graph);
}

function registerCustomShapes(graph) {
  registerCallout(graph);
}

function registerCallout() {
  function CalloutShape() {
    mxEllipse.call(this);
  }

  mxUtils.extend(CalloutShape, mxEllipse);

  CalloutShape.prototype.paintVertexShape = function (c, x, y, w, h) {
    if (this.style.direction === 1 || this.style.direction === 3) {
      this.style.align = 'left';
    } else {
      this.style.align = 'right';
    }

    const points = calculateCalloutLinePoints(
      { width: w, height: h, direction: this.style.direction },
      dc.UNIT_SIZE,
      x,
      y
    );
    c.begin();
    points.forEach(({ x, y }, i) => {
      i === 0 ? c.moveTo(x, y) : c.lineTo(x, y);
    });
    c.stroke();
  };

  mxCellRenderer.registerShape('callout', CalloutShape);
}

export function setVertexStyles(graph) {
  const styles = graph.getStylesheet().getDefaultVertexStyle();
  styles[mxConstants.STYLE_FONTSIZE] = dc.FONT_SIZE;
  styles[mxConstants.STYLE_STROKECOLOR] = dc.DEFAULT_STROKE_COLOR;
  styles[mxConstants.STYLE_FILLCOLOR] = dc.DEFAULT_FILL_COLOR;
  styles[mxConstants.STYLE_FONTCOLOR] = dc.DEFAULT_FONT_COLOR;
  styles[mxConstants.STYLE_STROKEWIDTH] = dc.DEFAULT_STROKE_WIDTH;
  styles[mxConstants.STYLE_FONTFAMILY] = dc.DEFAULT_FONT_FAMILY;
  styles[mxConstants.STYLE_SPACING_LEFT] = dc.DEFAULT_LABEL_PADDING;
  styles[mxConstants.STYLE_SPACING_RIGHT] = dc.DEFAULT_LABEL_PADDING;
}

export function setEdgeStyles(graph) {
  const style = graph.getStylesheet().getDefaultEdgeStyle();
  style[mxConstants.STYLE_CURVED] = dc.DEFAULT_EDGE_CURVE;
  // style[mxConstants.STYLE_EDGE] = dc.DEFAULT_EDGE_STYLE;
  style[mxConstants.STYLE_STROKECOLOR] = dc.DEFAULT_STROKE_COLOR;
}

export function addGraphListeners(graph, instance) {
  const listeners = new GraphListeners(graph, instance);
  graph.addListener(mxEvent.EDITING_STARTED, listeners.handleEditingStarted.bind(listeners));
  graph.addListener(mxEvent.CELLS_MOVED, listeners.updateCells.bind(listeners));
  graph.addListener(mxEvent.CELL_CONNECTED, listeners.handleCellConnected.bind(listeners));
  graph.addListener(mxEvent.CELLS_RESIZED, listeners.updateCells.bind(listeners));
  graph.addListener(mxEvent.LABEL_CHANGED, listeners.handleLabelChange.bind(listeners));
  graph.addListener(mxEvent.CELLS_MOVED, listeners.handleCellsMoved.bind(listeners));
  graph.addListener(mxEvent.CLICK, (sender, e) => {
    const cell = e.getProperty('cell');
    if (cell && graph.isCellEditable(cell)) {
      graph.startEditingAtCell(cell);
      const { textarea } = graph.cellEditor;
      const range = document.createRange();
      range.selectNodeContents(textarea);
      range.collapse(false);
      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
      instance.$gtag.event(ak.ACTION_CLICK, { event_category: ak.CATEGORY_FIG_LABEL_EDITOR });
    }
  });
}

export async function updateIllustration(url, graph) {
  const { height, width } = calculateImageDimensions(url);

  const image = new mxImage(url, width, height);
  graph.setBackgroundImage(image);
  graph.refresh();
}

export function repositionCells(graph) {
  const cells = graph.getChildCells();
  let widestWidth = 0;
  let horzEnd = dc.PANEL_WIDTH;
  cells.forEach((cell, i) => {
    if (cell.edge) {
      graph.removeCells([cell]);
    } else if (cell.style.includes('shape=image')) {
      graph.removeCells([cell]);
    } else if (cell.block && 'width' in cell.block) {
      const prev = getPrevCell(cells, i);
      cell.block.width = getTextWidth(cell.block.label, dc.FONT_SIZE) / dc.UNIT_SIZE + 3;
      cell.block.height = 3;
      cell.block.x = horzEnd - cell.block.width - 1;
      cell.block.y = prev ? prev.block.y + prev.block.height + 1 : 1;
      cell.block.shape = dc.SHAPE_LABEL;

      if (cell.block.width > widestWidth) widestWidth = cell.block.width;

      if (cell.block.y + cell.block.height >= dc.PANEL_HEIGHT) {
        horzEnd -= widestWidth;
        cell.block.y = 1;
        cell.block.x = horzEnd - cell.block.width - 1;
      }
    }
  });
}

function getPrevCell(cells, index) {
  const cell = cells[index - 1];
  if (
    !cell.style.includes('shape=image') &&
    'width' in cell?.block &&
    (cell.value !== 'Start') & (cell.value !== 'End')
  ) {
    return cell;
  }
  return null;
}

export function insertIllustration(url, graph) {
  const image = new mxImage(url, dc.STANDARD_WIDTH, dc.STANDARD_HEIGHT);
  graph.setBackgroundImage(image);
}

async function calculateImageDimensions(url) {
  const imageData = await getImageData(url);
  const imageAspectRatio =
    imageData.height > imageData.width
      ? imageData.width / imageData.height
      : imageData.height / imageData.width;

  let height = 0;
  let width = 0;
  let x = 0;
  let y = 0;

  if (imageData.height > imageData.width) {
    height = dc.WORKSPACE_HEIGHT * dc.UNIT_SIZE;
    width = height * imageAspectRatio;
    const xOffset = (dc.WORKSPACE_WIDTH - width / dc.UNIT_SIZE) / 2;
    y = dc.TOP_PADDING * dc.UNIT_SIZE;
    x = (dc.SIDE_PADDING + xOffset) * dc.UNIT_SIZE;
  } else {
    width = dc.WORKSPACE_WIDTH * dc.UNIT_SIZE;
    height = width * imageAspectRatio;
    const yOffset = (dc.WORKSPACE_HEIGHT - height / dc.UNIT_SIZE) / 2;
    x = dc.SIDE_PADDING * dc.UNIT_SIZE;
    y = (dc.TOP_PADDING + yOffset) * dc.UNIT_SIZE;
  }

  return { height, width, x, y };
}

export async function createImage(url, graph) {
  const { height, width, x, y } = await calculateImageDimensions(url);

  const dataUrl = url.replace(';base64', '');

  graph.insertVertex(
    graph.getDefaultParent(),
    null,
    null,
    x,
    y,
    width,
    height,
    `${dc.BLOCK_IMG_STYLE};imageWidth=${width};imageHeight=${height};image=${dataUrl}`
  );
}

/**
 * Inserts a borderless block into diagram
 */
export function insertBorderlessBlock({ currentBlock, graph, parent }) {
  graph.insertVertex(
    parent,
    null,
    `${currentBlock.text} <u>${currentBlock.label}</u>`,
    currentBlock.x * dc.UNIT_SIZE,
    currentBlock.y * dc.UNIT_SIZE,
    160,
    30,
    'align=left;strokeColor=none;fill=none;whiteSpace=wrap;'
  );
}

function formatBlockText(block, isParent = false, truncateText = true) {
  let blockWidth = isParent ? block.width * dc.UNIT_SIZE - 2 : block.width * dc.UNIT_SIZE;
  blockWidth -= (dc.DEFAULT_LABEL_PADDING + 2) * 2;
  const textToMeasure = getTextToMeasure(block);
  let maxChars = getMaxChars(textToMeasure, blockWidth, dc.FONT_SIZE);

  // Adjust maximum characters to account for the label in parent blocks or the extra space in child blocks
  isParent ? (maxChars -= block.label.length - 1) : (maxChars += 1);

  // Truncate the text at the maximum characters if it will wrap
  const willWrap = checkWillWrap(textToMeasure, blockWidth, dc.FONT_SIZE);
  const text = willWrap && truncateText ? truncate(block.text, maxChars) : block.text;

  // Determine the separator. Parent blocks separate text and labels with a space. Child blocks separate with a new line
  const separator = block.text.length === 0 ? '' : isParent ? ' ' : '\n';

  // Return the formatted block text
  return block.text && block.shape !== dc.SHAPE_LABEL
    ? block.label
      ? `${text}${separator}<u>${block.label}</u>`
      : text
    : `${block.label}`;
}

/**
 * Determine if the block label should be included in the text we are measuring
 * @param block
 * @returns {string} - String to measure. If block is a parent will include the block label, otherwise it will just be the block text
 */
function getTextToMeasure(block) {
  const isParent = block.children.length > 0;
  return isParent ? (block.label ? `${block.text} <u>${block.label}</u>` : block.text) : block.text;
}

/**
 * Inserts a block into the diagram
 */
export function insertBlock({ currentBlock, graph, parent }, truncate = true) {
  const isParent = currentBlock.children.length > 0;
  const dontRender = !currentBlock.label.trim() && currentBlock.shape === dc.SHAPE_LABEL;
  const text = formatBlockText(
    currentBlock,
    isParent && currentBlock.shape !== dc.SHAPE_LABEL,
    truncate
  );

  if (!dontRender) {
    const cell = graph.insertVertex(
      parent,
      currentBlock.id,
      text,
      currentBlock.x * dc.UNIT_SIZE,
      currentBlock.y * dc.UNIT_SIZE,
      currentBlock.width * dc.UNIT_SIZE,
      currentBlock.height * dc.UNIT_SIZE,
      currentBlock.shape === dc.SHAPE_LABEL
        ? currentBlock.direction === 0
          ? dc.BLOCK_LABEL_STYLE
          : currentBlock.direction === 1
          ? dc.BLOCK_LABEL_BOTTOM_RIGHT
          : currentBlock.direction === 2
          ? dc.BLOCK_LABEL_TOP_LEFT
          : dc.BLOCK_LABEL_TOP_RIGHT
        : isParent
        ? dc.BLOCK_PARENT_STYLE
        : currentBlock.shape === dc.SHAPE_PILL
        ? dc.BLOCK_PILL_STYLE
        : dc.BLOCK_CHILD_STYLE
    );

    cell.block = currentBlock;
  }
}

/**
 * Sets arrow style based on Link type
 * @param {string} type - Link type
 * @returns {String} Return Link arrow style
 */
export function getArrowDirections(type) {
  if (type === dc.UNDIRECTED) {
    return dc.STYLE_UNDIRECTED;
  }

  if (type === dc.OUTBOUND) {
    return dc.STYLE_OUTBOUND;
  }

  if (type === dc.INBOUND) {
    return dc.STYLE_INBOUND;
  }

  if (type === dc.BIDIRECTED) {
    return dc.STYLE_BIDIRECTED;
  }
}

/**
 * Point units are 1/72 of an inch so we multiply the desired fontSize by 1/72
 *
 * @param fontSize
 * @return {number}
 */
export function convertPtToIn(fontSize) {
  return fontSize * (1 / 72);
}

export function convertPxToIn(n) {
  return n * (1 / 96);
}

export function getVisioFontSize() {
  const ptSize = Math.round(dc.FONT_SIZE * 0.75);
  return convertPtToIn(ptSize);
}

export function getVisioFigFontSize() {
  const ptSize = Math.round(dc.FIG_FONT_SIZE * 0.75);
  return convertPtToIn(ptSize);
}

function intervalLoop(iterable, onInterval, ms) {
  return new Promise((resolve) => {
    let i = 0;
    const length = iterable.length;
    const interval = setInterval(async () => {
      if (i === length) {
        clearInterval(interval);
        resolve();
      } else {
        onInterval(iterable[i]);
      }
      i++;
    }, ms);
  });
}

export async function renderBlocks(blocks, graph, parent, sequential, truncate) {
  if (sequential) {
    return intervalLoop(
      blocks,
      (block) => {
        renderBlock(block, graph, parent, truncate);
      },
      50
    );
  } else {
    blocks.forEach((block) => {
      renderBlock(block, graph, parent, truncate);
    });
  }
}

export async function renderLinks(links, graph, parent, sequential) {
  if (sequential) {
    return intervalLoop(
      links,
      (link) => {
        renderLink(link, graph, parent);
      },
      50
    );
  } else {
    links.forEach((link) => {
      renderLink(link, graph, parent);
    });
  }
}

export function renderBlock(currentBlock, graph, parent, truncate) {
  if (currentBlock.width === 0) {
    insertBorderlessBlock({ currentBlock, parent, graph });
  } else {
    insertBlock({ currentBlock, parent, graph }, truncate);
  }
}

export function renderLink(link, graph, parent) {
  const model = graph.getModel();
  const source = model.getCell(link.head);
  const target = model.getCell(link.tail);
  const arrowDirections = getArrowDirections(link.type);

  graph.insertEdge(parent, link.id, null, source, target, `${arrowDirections}`);
}

export function renderFigNumber(num, graph, parent) {
  const text = `${dc.FIG_TAG_START}FIG. ${num}${dc.FIG_TAG_END}`;
  const fig = graph.insertVertex(
    parent,
    null,
    text,
    dc.FIG_X,
    dc.FIG_Y,
    dc.FIG_WIDTH * dc.UNIT_SIZE,
    dc.FIG_HEIGHT * dc.UNIT_SIZE,
    dc.FIG_STYLE
  );
  fig.setConnectable(false);
  fig.block = { text };
  graph.updateCellSize(fig);
}

/**
 * Configures mouse listeners on mxGraph
 * @param {Object} graph - mxGraph Object
 * @returns {Object} - mouseListener Object
 */
export function mouseListeners(graph) {
  return {
    currentState: null,
    previousStyle: null,
    previousCellStyle: null,

    mouseDown() {},
    mouseUp(sender, mouseEvent) {
      if (this.currentState !== null) {
        this.dragLeave(mouseEvent.getEvent(), this.currentState);
        this.currentState = null;
      }
    },

    mouseMove(sender, me) {
      if (graph.isCellEditable(me.state?.cell)) {
        if (this.currentState !== null && me.getState() === this.currentState) {
          return;
        }

        let tmp = graph.view.getState(me.getCell());
        if (graph.isMouseDown || (tmp !== null && !graph.getModel().isVertex(tmp.cell))) {
          tmp = null;
        }

        if (tmp !== this.currentState) {
          if (this.currentState !== null) {
            this.dragLeave(me.getEvent(), this.currentState);
          }

          this.currentState = tmp;

          if (this.currentState !== null) {
            this.dragEnter(me.getEvent(), this.currentState);
          }
        }
      }
    },

    dragEnter(evt, state) {
      if (graph.isCellEditable(state?.cell)) {
        if (state !== null) {
          this.previousStyle = state.style;
          this.previousCellStyle = state.cell.style;
          state.style = mxUtils.clone(state.style);
          if (state.shape) {
            state.shape?.apply(state);
            state.shape?.redraw();
          }

          if (state.text) {
            state.text?.apply(state);
            state.text?.redraw();
          }
        }
      }
    },
    dragLeave(evt, state) {
      if (graph.isCellEditable(state?.cell)) {
        if (state !== null) {
          state.style = this.previousStyle;
          if (state.shape) {
            state.shape?.apply(state);
            state.shape?.redraw();
          }

          if (state.text) {
            state.text?.apply(state);
            state.text?.redraw();
          }
        }
      }
    },
  };
}

/*
  This file configures mxGraph
*/

import mx from 'mxgraph';

const mxgraph = mx({
  mxImageBasePath: '/src/assets',
  mxBasePath: './src',
});

window.mxGraph = mxgraph.mxGraph;
window.mxGraphModel = mxgraph.mxGraphModel;
window.mxEditor = mxgraph.mxEditor;
window.mxGeometry = mxgraph.mxGeometry;
window.mxDefaultKeyHandler = mxgraph.mxDefaultKeyHandler;
window.mxDefaultPopupMenu = mxgraph.mxDefaultPopupMenu;
window.mxStylesheet = mxgraph.mxStylesheet;
window.mxDefaultToolbar = mxgraph.mxDefaultToolbar;
window.mxConnectionHandler = mxgraph.mxConnectionHandler;
window.mxEvent = mxgraph.mxEvent;
window.Rubberband = mxgraph.mxRubberband;
window.mxClient = mxgraph.mxClient;
window.mxUtils = mxgraph.mxUtils;

export default mxgraph;

<template>
  <v-tooltip
    :bottom="bottom"
    :left="left"
    :right="right"
    :top="top"
    v-model="show"
    max-width="300px"
    :content-class="tooltipClass"
    transition="my-transition"
  >
    <template v-slot:activator="scope">
      <slot name="activator" v-bind="scope" />
    </template>
    <div @mouseenter="mouseEnter" @mouseleave="mouseLeave">
      <v-btn light icon @click="closeTooltip" class="align-self-end mt-1 mr-1" small
        ><v-icon>mdi-close</v-icon></v-btn
      >
      <p>{{ message }}</p>
    </div>
  </v-tooltip>
</template>

<script>
import { mapMutations, mapState } from 'vuex';

export default {
  name: 'Tooltip',
  props: {
    message: String,
    value: Boolean,
    id: String,
    leftEdge: {
      type: Boolean,
      default: false,
    },
    rightEdge: {
      type: Boolean,
      default: false,
    },
    left: {
      type: Boolean,
      default: false,
    },
    right: {
      type: Boolean,
      default: false,
    },
    bottom: {
      type: Boolean,
      default: false,
    },
    top: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hovering: false,
      interval: null,
    };
  },

  computed: {
    ...mapState({ showTooltip: (state) => state.tooltip.showTooltip }),

    tooltipClass() {
      let str = 'custom-tooltip';

      if (this.top) str += ' bottom-arrow';
      if (this.bottom) str += ' top-arrow';
      if (this.right) str += ' left-arrow';
      if (this.left) str += ' right-arrow';
      if (this.leftEdge) str += ' left-edge';
      if (this.rightEdge) str += ' right-edge';

      return str;
    },

    show: {
      get() {
        return this.showTooltip === this.id;
      },

      set(val) {
        if (val) {
          this.setTooltipState({ key: 'showTooltip', val: this.id });
        } else {
          this.closeTooltip();
        }
      },
    },
  },

  methods: {
    ...mapMutations('tooltip', ['setTooltipState', 'toggleTooltip']),

    closeTooltip() {
      this.setTooltipState({ key: 'showTooltip', val: null });
    },

    mouseLeave() {
      this.setTooltipState({ key: 'hoveringTooltip', val: null });
    },

    mouseEnter() {
      this.setTooltipState({ key: 'hoveringTooltip', val: this.id });
      if (!this.show) {
        this.toggleTooltip(this.id);
      }
    },
  },
};
</script>

<style scoped></style>

const state = {
  hoveredConcept: null,
  focusedConcept: null,
  tagAll: null,
  deleteAll: null,
  focusedTag: null,
  conceptMenu: {
    mouseover: false,
    show: false,
    x: 0,
    y: 0,
    outlineIndex: 0,
  },
};

export default state;

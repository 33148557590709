import { v4 as uuid } from 'uuid';

/**
 * Internal tag object of outline editor
 * @memberOf Internal
 * @property {String} id - UUID string
 * @property {String} text - text of tag
 * @property {Number} start - start index of tag
 * @property {Internal.Concept} concept - concept tag refers to
 */
export default class Tag {
  constructor(data) {
    this.id = data.id || uuid();
    this.text = data.text || '';
    this.start = data.start;
    if (data.type) this.type = data.type;
    if (data.concept !== undefined) this.concept = data.concept || null;
    if (data.head_ref !== undefined) this.head = data.head_ref;
    if (data.tail_ref !== undefined) this.tail = data.tail_ref;
  }
}

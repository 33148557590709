<template>
  <v-alert type="info" color="primary" dense text class="mb-10">
    This is a development version of PatentPal Draft. Please visit the official app
    <a :href="link" class="primary-text"> here</a>.
  </v-alert>
</template>

<script>
export default {
  name: 'EnvironmentWarning',
  props: {
    link: String,
  },
};
</script>

<style scoped></style>

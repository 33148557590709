/**
 * Configured Veutify
 */

import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#e16c6c',
        accent: '#fc6666',
        secondary: '#83D1FE',
        lightGray: '#E5E5E5',
        darkBackground: '#4E4E4E',
      },
    },
  },
});

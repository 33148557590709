/**
 * This is where Vuex is initialized and configured
 *
 * Vuex docs: https://vuex.vuejs.org/
 */

import Vue from 'vue';
import Vuex from 'vuex';
import draft from './modules/draft';
import outline from './modules/outline';
import upload from './modules/upload';
import error from './modules/error';
import tooltip from './modules/tooltip';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    draft,
    outline,
    upload,
    error,
    tooltip,
  },
});

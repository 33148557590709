<template>
  <SignupLayout>
    <v-overlay :value="loading">
      <v-progress-circular size="100" indeterminate />
    </v-overlay>
    <v-container tag="section" id="subscribe">
      <v-btn text v-if="!showSkipButton" to="/" class="back-button top-button">
        <v-icon left>mdi-arrow-left</v-icon>
        Back to app
      </v-btn>
      <v-btn text v-show="showSkipButton" to="/" class="skip-button top-button">
        Skip for now
        <v-icon right>mdi-arrow-right</v-icon>
      </v-btn>
      <h1>{{ text.title }}</h1>
      <h6>
        {{ text.subtitle }}
      </h6>

      <!-- Subscription plans -->
      <div class="subscription-plans">
        <v-card
          outlined
          rounded
          width="155"
          height="140"
          v-for="(plan, index) in plans"
          :key="`plan-${index}`"
          :class="`plan ${plan.active ? 'active' : ''}`"
          @click="selectPlan(index)"
          :disabled="plan.active"
        >
          <h3>
            {{ plan.price }}<span>{{ text.per }}</span>
          </h3>
          <span>{{ plan.text }}</span>
        </v-card>
      </div>
      <!-- End subscription plans -->

      <v-form ref="form" class="payment-form">
        <!-- Saved payment methods header -->
        <div v-if="!showForm || addNewCard" class="payment-header-container">
          <h2 ref="paymentHeader">Payment methods</h2>
          <v-btn text @click="manageSubscription">
            <v-icon left color="grey darken-2">mdi-credit-card-edit</v-icon>
            <span class="edit-button">Update</span>
          </v-btn>
        </div>
        <!-- End saved payment methods header -->

        <!-- Saved payment methods list -->
        <div :class="`paymentMethods ${showForm ? 'collapsed' : ''}`">
          <CardBlock
            v-for="(item, index) in paymentMethods"
            :key="index"
            :paymentMethod="item"
            :selectedCard="selectedMethod"
            @clicked="handleSelectCard"
          />
          <v-btn text @click="toggleForm" class="subscribe-button">
            <v-icon left size="20">mdi-plus</v-icon>
            <span class="edit-button">Add payment method</span>
          </v-btn>
        </div>
        <!-- End saved payment methods list -->

        <!-- New payment method form -->
        <div :class="`new-card-inputs${showForm ? ' show' : ''}`">
          <h2>Card information</h2>
          <Payment v-model="billingInfo" ref="payment" @errorCheck="checkCardError" />
          <h2>Billing address</h2>
          <Billing v-model="billingInfo" />

          <v-checkbox
            label="Set default"
            v-model="setDefault"
            v-if="showSetDefault"
            :ripple="false"
            :hide-details="true"
            class="set-default-checkbox"
          />
        </div>
        <!-- End new payment method form -->
      </v-form>
      <v-btn
        rounded
        color="primary"
        width="180"
        height="40"
        class="subscribe-button"
        @click="subscribe"
        :disabled="disabled"
      >
        {{ text.confirm }}
      </v-btn>

      <!-- Error message element -->
      <Message
        v-model="snackbar.value"
        color="error"
        :message="snackbar.message"
        @close="snackbar.value = false"
        absolute
      />
      <!-- Error message element -->
    </v-container>
  </SignupLayout>
</template>

<script>
import { SignupLayout } from '../layouts';
import { Billing, Payment, CardBlock } from '@/components/subscribe';
import { Message } from '@/components/global';
import { createSubscription, getUserData } from '@/support/alice';
import { MONTH_SUB, YEAR_SUB } from '@/data/var.config';
import {
  SUB_ANNUAL,
  SUB_CONFIRM,
  SUB_MONTH,
  SUB_PER,
  SUB_SUBTITLE,
  SUB_TITLE,
  SUBSCRIBE_SUCCESS,
} from '@/data/constants/constantsMessages';
import { ErrorKeys as ek } from '@/data/constants';
import config from '@/data/var.config';
import { mapMutations, mapState } from 'vuex';
import axios from 'axios';

export default {
  name: 'SubscribeView',
  components: {
    SignupLayout,
    CardBlock,
    Message,
    Billing,
    Payment,
  },
  data() {
    return {
      loadingCards: false,
      loading: false,
      clientSecret: null,
      cardError: true,
      signedUp: false,
      setDefault: false,
      refresh: null,
      timeout: null,
      showProfilePrompt: false,
      showGeneratePrompt: false,
      addNewCard: false,
      snackbar: {
        value: false,
        error: false,
        message: '',
      },
      paymentMethods: [],
      selectedMethod: null,
      plans: [
        {
          price: SUB_MONTH,
          text: 'Renews every month',
          active: true,
          priceId: MONTH_SUB,
        },
        {
          price: SUB_ANNUAL,
          text: 'Renews every year',
          active: false,
          priceId: YEAR_SUB,
        },
      ],
      billingInfo: {
        nameOnCard: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        country: 'US',
        zipCode: '',
      },
      text: {
        title: SUB_TITLE,
        subtitle: SUB_SUBTITLE,
        per: SUB_PER,
        confirm: SUB_CONFIRM,
      },
    };
  },

  metaInfo: {
    title: 'Subscribe',
  },

  async mounted() {
    this.setPaymentMethodsHeight();

    if (this.$auth?.user.sub !== this.$route.params.id) {
      this.$router.replace('/');
    }
  },

  async beforeRouteEnter(to, from, next) {
    try {
      const id = to.params.id;
      const { data } = await getUserData(id);
      next((vm) => vm.setData(null, data));
    } catch (err) {
      console.error(err);
      next('/');
    }
  },

  async beforeRouteUpdate(to, from, next) {
    this.paymentMethods = [];
    this.selectedMethod = null;
    try {
      const id = to.params.id;
      const { data } = await getUserData(id);
      this.setData(null, data);
      next();
    } catch (error) {
      next('/');
    }
  },

  watch: {
    '$auth.user': {
      deep: true,
      handler(user) {
        if (user) {
          if (user.appData.subscribed) {
            this.$router.replace('/');
            this.signedUp = false;
            this.loading = false;
            if (user.metaData.tutorial) {
              this.setErrorState({
                key: ek.SUCCESS,
                val: true,
                message: SUBSCRIBE_SUCCESS,
              });
            }
            clearInterval(this.refresh);
            clearTimeout(this.timeout);
          }
        }
      },
    },
  },

  computed: {
    ...mapState({ rootScroll: (state) => state.draft.rootScroll }),
    /**
     * @returns {Boolean}
     */
    disabled() {
      return (
        (this.cardError === true ||
          this.billingInfo.nameOnCard.length < 1 ||
          this.billingInfo.address1.length < 1 ||
          this.billingInfo.city.length < 1 ||
          this.billingInfo.state.length < 1 ||
          this.billingInfo.country.length < 1 ||
          this.billingInfo.zipCode.length < 1) &&
        !this.selectedMethod
      );
    },

    /**
     * @returns {Boolean}
     */
    showSkipButton() {
      return (
        this.auth.isAuthenticated() &&
        this.$route.name === 'subscribe' &&
        this.$route.params.login === 'true'
      );
    },

    /** @returns {Object} */
    auth() {
      return this.$auth;
    },

    /**
     * @returns {string}
     */
    backText() {
      return this.$route.params.login === 'true' ? 'Skip for now' : 'Back to app';
    },

    /**
     * @returns {boolean}
     */
    showForm() {
      return (this.paymentMethods.length === 0 || this.addNewCard) && !this.loadingCards;
    },

    /**
     * @returns {boolean}
     */
    showSetDefault() {
      return this.paymentMethods.length > 0 && !this.$route.params.login;
    },
  },

  beforeDestroy() {
    clearInterval(this.refresh);
  },

  methods: {
    ...mapMutations({
      setErrorState: 'error/setErrorState',
      saveUserContent: 'draft/saveUserContent',
    }),

    setData(err, data) {
      this.paymentMethods = data.payment_methods;
      this.selectedMethod = this.paymentMethods[0];
    },

    toggleForm() {
      this.selectedMethod = null;
      this.addNewCard = true;
      this.setPaymentMethodsHeight(true);
    },

    async manageSubscription() {
      const { sub } = this.$auth.user;
      try {
        this.loading = true;
        const res = await axios.post(`${config.USER_MANAGEMENT_URL}/customer-portal`, {
          user_id: sub,
        });
        this.saveUserContent();
        window.location.href = res.data.session_url;
      } catch (err) {
        this.loading = false;
        console.error(err);
      }
    },

    async subscribe() {
      if (this.validateForm() || this.selectedMethod) {
        this.loading = true;
        try {
          let paymentMethod = this.selectedMethod;

          if (!paymentMethod) {
            const temp = await this.createPaymentMethod();
            paymentMethod = temp.paymentMethod;
          }

          const data = this.packPaymentData(paymentMethod);

          const { data: resData } = await createSubscription(data);
          if (resData.clientSecret)
            await this.confirmPayment(resData.clientSecret, paymentMethod.id);
          this.waitForFinalization();
        } catch (err) {
          this.handleError(err.response?.data?.errorMessage);
        }
      }
    },

    selectPlan(index) {
      this.plans.forEach((plan, i) => {
        plan.active = i === index;
      });
    },

    setPaymentMethodsHeight(collapse = false) {
      const $payments = document.querySelector('.paymentMethods');
      const cardHeight = 90;
      if (collapse) {
        $payments.style.height = '70px';
        $payments.style.marginBottom = '30px';
      } else {
        const activeCardHeight = 160;
        const totalCards = this.paymentMethods.length;
        if (totalCards > 0) {
          $payments.style.height = `${(totalCards - 1) * cardHeight + activeCardHeight + 35.99}px`;
          $payments.style.marginBottom = '0';
        } else {
          $payments.style.height = '0';
          $payments.style.marginBottom = '0';
        }
      }
    },

    handleSelectCard({ paymentMethod }) {
      if (this.addNewCard) {
        this.setPaymentMethodsHeight();
        this.addNewCard = false;
        this.selectedMethod = paymentMethod;
      } else {
        this.selectedMethod = paymentMethod;
      }
    },

    validateForm() {
      return this.$refs.form.validate();
    },

    async createPaymentMethod() {
      return this.$refs.payment.$refs.cardInput.$data.stripe.createPaymentMethod({
        type: 'card',
        card: this.$refs.payment.$refs.cardInput.$data.element,
        billing_details: {
          name: this.billingInfo.nameOnCard,
          address: {
            city: this.billingInfo.city,
            state: this.billingInfo.state,
            country: this.billingInfo.country,
            line1: this.billingInfo.address1,
            line2: this.billingInfo.address2,
            postal_code: this.billingInfo.zipCode,
          },
        },
      });
    },

    packPaymentData(paymentMethod) {
      return {
        priceId: this.plans.find((plan) => plan.active).priceId,
        email: this.$auth.user.email,
        address: this.billingInfo,
        setDefault: this.setDefault,
        paymentMethod,
        ...(this.$auth.user.appData && { customerId: this.$auth.user.appData.stripe_id }),
      };
    },

    async confirmPayment(clientSecret, paymentMethod) {
      const res = await this.$stripe.confirmCardPayment(clientSecret, {
        payment_method: paymentMethod,
      });

      if (res.error) {
        this.handleError(res.error.message);
      } else {
        return res;
      }
    },

    waitForFinalization() {
      this.signedUp = true;
      this.refresh = setInterval(() => {
        this.$auth.refresh();
      }, 1000);
      this.timeout = setTimeout(() => {
        clearInterval(this.refresh);
        this.snackbar.error = true;
        this.snackbar.message =
          'Request took longer than expected. Please refresh the page and try again.';
      }, 10000);
    },

    handleError(err) {
      const message = err
        ? err.replace(/^Request\sreq_[a-z\d]*:\s/i, '')
        : 'An error occurred. Please try refreshing the page. If problem persists please contact support@patentpal.com';
      this.loading = false;
      this.snackbar.value = true;
      this.snackbar.error = true;
      this.snackbar.message = message;
    },

    checkCardError(e) {
      if (e.error) {
        this.cardError = true;
      }

      if (e.empty) {
        this.cardError = true;
      }

      if (!e.complete) {
        this.cardError = true;
      }

      if (!e.empty && !e.error && e.complete) {
        this.cardError = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$page-height: calc(100vh - 74px - 16px);
$normal-gap: 20px;
$large-gap: 30px;
$small-gap: 10px;
$transition-speed: 0.3s;

#subscribe {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $app-text-color;

  h1 {
    font-weight: normal;
    font-size: 34px;
  }

  h2 {
    font-weight: 500;
    font-size: 20px;
    margin-bottom: $normal-gap;
  }

  h6 {
    font-weight: normal;
    font-size: 16px;
  }

  .top-button {
    margin-bottom: $large-gap;
    &.back-button {
      align-self: start;
    }

    &.skip-button {
      align-self: end;
    }
  }

  .subscription-plans {
    margin-top: $large-gap;
    margin-bottom: $large-gap;
    display: flex;
    justify-content: center;
    .plan {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      transition: border-color $transition-speed, box-shadow $transition-speed;
      border: 2px solid transparent;
      margin: 0 15px;

      &:hover {
        border-color: $focus;
        border-width: 2px;
        box-shadow: 0 0 10px 1px lightgray;
      }

      &.active {
        border-color: $focus;
        h3,
        span {
          opacity: 1 !important;
        }
      }

      h3 {
        font-weight: normal;
        font-size: 24px;
        color: $app-text-color;

        span {
          font-size: 16px;
          color: inherit;
        }
      }
      span {
        font-size: 12px;
        color: $strong-gray;
      }
    }
  }

  .payment-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $normal-gap;
    h2 {
      margin: 0;
    }
    .v-btn {
      height: 30px !important;
      padding: 0 12px;
    }
  }

  .paymentMethods {
    transition: height $transition-speed, margin-bottom $transition-speed !important;
    overflow: hidden;
  }

  .new-card-inputs {
    transition: max-height $transition-speed;
    overflow: hidden;
    max-height: 0;
    &.show {
      max-height: 500px;
    }
  }

  .payment-form {
    margin-bottom: $large-gap;
    overflow: hidden;
  }

  .add-payment-button {
    font-size: 16px !important;
  }

  .set-default-checkbox {
    margin-top: 0px !important;
    padding: 0px !important;
  }

  .subscribe-button {
    padding: 0 15px !important;
  }

  .container {
    width: 100%;
    padding: 0;
    position: relative;
    min-height: $page-height;
  }

  .back-button {
    font-weight: normal !important;
  }

  .edit-button {
    font-size: 16px !important;
  }
}
</style>

import { UploadKeys as k } from '@/data/constants';

export default {
  [k.FILE]: null,
  [k.UPLOADING]: false,
  [k.PROGRESS]: 0,
  [k.DROP]: false,
  [k.DIALOG]: false,
  [k.OVERWRITE]: false,
};

/**
 * Configures Router
 */

import VueRouter from 'vue-router';
import { GenerateView, NotFound, SignupView, SubscribeView, CallbackView, Login } from '@/views';

import config from '../data/var.config.js';

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: GenerateView,
    },

    {
      path: '/signup',
      name: 'signup',
      component: SignupView,
      beforeEnter: (to, from, next) => {
        if (router.app.$auth.isAuthenticated()) {
          next('/');
        }

        if (config.LOGIN_DOMAIN === 'patentpal.us.auth0.com') {
          router.app.$auth.universalLogin();
        }

        next();
      },
    },

    {
      path: '/subscribe/',
      name: 'subscribe',
      component: SubscribeView,
    },

    {
      path: '/login',
      name: 'login',
      component: Login,
      beforeEnter: (to, from, next) => {
        if (router.app.$auth.isAuthenticated()) {
          next('/');
        }

        if (config.LOGIN_DOMAIN === 'patentpal.us.auth0.com') {
          router.app.$auth.universalLogin();
        }

        next();
      },
    },

    {
      path: '/callback',
      name: 'callback',
      component: CallbackView,
    },

    { path: '*', name: '404', component: NotFound },
  ],
});

router.beforeEach(async (to, from, next) => {
  if (to.name === 'callback') {
    next();
  } else if (
    !router.app.$auth.isAuthenticated() &&
    window &&
    to.name !== 'login' &&
    to.name !== 'signup'
  ) {
    await router.app.$auth.checkSession();
    if (!router.app.$auth.isAuthenticated()) {
      next('/login');
    }
  }
  next();
});

export default router;

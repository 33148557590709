import Point from './Point';
import Concept from './Concept';
import { v4 as uuid } from 'uuid';

/**
 * Internal outline data for outline editor
 * @memberOf Internal
 * @property {String} id - UUID string
 * @property {Concept[]} concepts - Array of concepts
 * @property {Point[]} points - Array of points
 */
export default class Outline {
  /**
   * Create a new InboundGraph
   * @param {ServerOutline} outline - Server outline data received from Alice API
   */
  constructor(outline) {
    this.id = outline.id || uuid();
    this.concepts = this.unpackConcepts(outline.concepts);
    this.points = this.unpackPoints(outline.points, outline.tags, []);
  }

  /**
   * Loop through points and reformat into a nested list
   * @param {ServerPoint[]} points - Array of Nodes from Alice graph API
   * @param {ServerTag[]} tags - Array of references from server
   * @param {ServerLink[]} links - Array of relationships from server
   * @returns {Point[]} - An array of points
   */
  unpackPoints(points, tags, links) {
    return points.reduce((newPoints, n) => {
      n.parent = null;
      const filteredParent = { ...n, parent: null };
      const point = new Point(filteredParent);
      if (point) {
        point.nestChildPoints(n.children, points, this.concepts, tags, links);
        point.setPrev(newPoints, null);
        point.packTags(this.concepts, tags);
        point.packLinks(this.concepts, links);
        newPoints.push(point);
      }
      return newPoints;
    }, []);
  }

  /**
   * Format concepts received from server for Outline editor
   * @param {ServerConcept[]} concepts - Concepts from server
   * @returns {Concept[]}
   */
  unpackConcepts(concepts) {
    return concepts.map((c) => {
      return new Concept(c);
    });
  }
}

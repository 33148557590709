<template>
  <v-hover key="index" #default="{ hover }">
    <div>
      <v-list-item
        @click="$emit('clicked', profile)"
        @dblclick="handleDoubleClick"
        :input-value="activeProfile === profile.id"
        :ripple="false"
      >
        <v-text-field
          v-model="inputValue"
          ref="input"
          v-show="showInput"
          @blur="confirmChange"
          @focus="inputValue = profile.name"
          @keypress.enter.prevent="(e) => e.target.blur()"
          height="30"
          hide-details
          color="black"
          class="pa-0 ma-0 customStyle"
        />
        <v-list-item-title v-show="!showInput">{{ profile.name }}</v-list-item-title>
        <v-fade-transition hide-on-leave>
          <v-list-item-action v-if="hover && !showInput">
            <v-btn icon @click="$emit('delete', index)">
              <v-icon small>mdi-close</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-fade-transition>
      </v-list-item>
    </div>
  </v-hover>
</template>

<script>
export default {
  name: 'ProfileListItem',
  props: {
    profile: Object,
    index: Number,
    activeProfile: String,
  },

  data() {
    return {
      inputValue: '',
    };
  },

  mounted() {
    if (this.showInput) {
      this.$refs.input.focus();
    }
  },

  computed: {
    /**
     * @returns {boolean}
     */
    showInput() {
      return this.profile.editing;
    },
  },

  methods: {
    confirmChange() {
      this.profile.name = this.inputValue;
      this.profile.editing = false;
      this.$emit('nameChanged');
    },

    handleDoubleClick() {
      this.profile.editing = true;
      this.$nextTick(() => {
        this.$refs.input.focus();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.configMenu {
  padding-bottom: 30px;

  // TODO: Do not show the hover background during drag and drop
  // .sortable-chosen {
  //   .v-list-item {
  //     &:before {
  //       opacity: 0;
  //     }
  //   }
  // }

  .v-list-item {
    padding-left: 30px !important;
  }

  .v-list-item__action {
    margin: 0 !important;
  }
}
</style>

import CryptoJS from 'crypto-js';
import config from '@/data/var.config';

export function encrypt(str) {
  return CryptoJS.AES.encrypt(str, config.KEY).toString();
}

export function decrypt(str) {
  const bytes = CryptoJS.AES.decrypt(str, config.KEY);
  return bytes.toString(CryptoJS.enc.Utf8);
}

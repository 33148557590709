/**
 * Link data formatted for Diagram Editor. Represents a connection between two
 * blocks
 */
export default class Connection {
  /**
   * creates a new Connection, which is formatted to be consumed by the Diagram
   * Editor. Links represent connections between blocks
   * @param {object} link - Object representing a link
   * @param {String} link.id - UUID string
   * @param {String} link.label - label text of string - *not currently supported*
   * @param {String} link.head - UUID of head block
   * @param {String} link.tail - UUID of tail block
   * @param {String} link.type - link type
   */
  constructor(link) {
    this.id = link.id;
    this.type = link.type;
    this.label = link.label;
    this.head = link.head;
    this.tail = link.tail;
  }

  /**
   * Converts the provided type into a corresponding type that can be consumed
   * by the Diagram Editor
   *
   * TODO refactor Diagram Editor links to differentiate between INBOUND and OUTBOUND types
   *
   * @param {String} type
   * @returns {String} converted type string
   */
  _convertType(type) {
    if (type === 'OUTBOUND') {
      return 'unidirectional';
    }

    if (type === 'INBOUND') {
      return 'unidirectional';
    }

    if (type === 'UNDIRECTED') {
      return 'associative';
    }

    if (type === 'BIDIRECTIONAL') {
      return 'bidirectional';
    }
  }
}

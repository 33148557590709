export default {
  setHoveredConcept(state, val) {
    state.hoveredConcept = val;
  },

  setFocusedConcept(state, val) {
    state.focusedConcept = val;
  },

  setTagAll(state, val) {
    state.tagAll = val;
  },

  deleteConcept(state, val) {
    state.deleteAll = val;
  },

  /**
   *
   * @param state
   * @param {Object} val
   * @param {Boolean} val.show
   * @param {Number} [val.x]
   * @param {Number} [val.y]
   * @param {Internal.Tags[]} [items]
   */
  setConceptMenu(state, val) {
    state.conceptMenu = { ...state.conceptMenu, ...val };
  },

  setFocusedTag(state, val) {
    state.focusedTag = val;
  },

  setMenuMouseover(state, val) {
    state.conceptMenu.mouseover = val;
  },
};

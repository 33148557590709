import { TOOLTIP_DURATION_MS } from '@/data/constants/constantsTooltip';

export default {
  setTooltipState(state, { key, val }) {
    state[key] = val;
  },

  toggleTooltip(state, val) {
    state.showTooltip = val;
    const interval = setInterval(() => {
      if (state.hoveringTooltip !== val) {
        state.showTooltip = null;
        clearInterval(interval);
      }
    }, TOOLTIP_DURATION_MS);
  },
};

/*
  Helper functions for API.js use this file for abstracting out any complex or
  repetitive code.
*/

import axios from 'axios';
import Figure from '@/data/Figure';
import { WORD_TRANSFER } from '@/data/var.config';

/**
 * Formats an error message and throws it to be caught in catch block
 * @param {String} error string error message from alice response
 */
export function formatAndThrowError(error) {
  return error.trim().length > 0 ? error : 'Server responded with an error but no message.';
}

/**
 * formats diagrams from provided JSON data
 * @param {JSON} data JSON diagram data from Alice response
 * @returns {object}
 */
export function formatDiagrams(data) {
  const { diagrams } = data;
  const formattedDiagrams = diagrams.map((d) => {
    const diagram = new Figure(d.number, d.id);
    diagram.createBlocks(d.boxes);
    diagram.createLinks(d.lines);
    return diagram;
  });
  return { diagrams: formattedDiagrams };
}

export async function requestDocClaims(pathToBucketFile) {
  const req = {
    request: 'WORD_IMPORT',
    data: {
      path: pathToBucketFile,
    },
    options: [],
  };
  const res = await axios.post(WORD_TRANSFER, req);
  const data = JSON.parse(res.data);

  return data.data.document;
}

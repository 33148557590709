import { v4 as uuid } from 'uuid';
import { findWithIndex } from '@/support/utilities/helpers';
import Tag from './Tag';
import Link from './Link';

/**
 * Internal point class for outline editor
 * @memberOf Internal
 * @property {String} id - UUID string
 * @property {Point[]} children - Nested array of other PointObjects
 * @property {String} head - UUID string of head concept
 * @property {?String} parent - UUID of parent point
 * @property {Object[]} tags
 * @property {String} text - Text string to be rendered in graph editor
 * @property {Boolean} collapsed - Boolean determining of point is collapsed or not
 * @property {Point} next - Next point in linked list
 * @property {Point} prev - Previous point in linked list
 */
export default class Point {
  /**
   * Create a new Point
   *
   * @param {ServerPoint} point - Node object sent by c2g {@link ServerPoint}
   * @param {Point} next - next point in linked list
   * @param {Point} prev - previous point in linked list
   */
  constructor(point, next = null, prev = null) {
    this.id = point.id || uuid();
    this.children = [];
    this.anchor = point.anchor || null;
    this.parent = point.parent || null;
    this.tags = point.tags || [];
    this.links = [];
    this.text = point.text || '';
    this.collapsed = false;
    this.next = next;
    this.prev = prev;
  }

  /**
   * Nest child points into current points child array
   * @param {String[]} children - Array of current points child point IDs
   * @param {Object[]} flatList - Flat array of all points in outline
   * @param {Concept[]} concepts - Array of outline concepts
   * @param {Object[]} tags - Array of tags on outline
   * @param {Object[]} links - Array of links on outline
   */
  nestChildPoints(children, flatList, concepts, tags, links) {
    this.children = children.reduce((accumulator, id) => {
      const { item, index } = findWithIndex(flatList, (f) => f.id === id);
      const point = this.createChild(item, flatList, accumulator, concepts, tags, links);
      flatList.splice(index, 1);
      accumulator.push(point);
      return accumulator;
    }, []);

    this.next = this.children[0] || null;
  }

  createChild(item, flatList, arr, concepts, tags, links) {
    const point = new Point(item);
    point.nestChildPoints(item.children, flatList, concepts, tags, links);
    point.setPrev(arr, this);
    point.packTags(concepts, tags);
    point.packLinks(concepts, links);
    return point;
  }

  setPrev(val, parent) {
    if (val.length > 0) {
      const prev = val[val.length - 1];
      if (prev) {
        if (!prev.next) {
          prev.next = this;
          this.prev = prev;
        } else {
          const newPrev = prev.getLastChild();
          newPrev.next = this;
          this.prev = newPrev;
        }
      }
    } else {
      this.prev = parent;
    }
  }

  getLastChild() {
    const lastChild = this.children[this.children.length - 1];
    if (lastChild && lastChild.children.length > 0) {
      return lastChild.getLastChild();
    }
    return lastChild;
  }

  insertIntoLinkedList(point) {
    if (point.next) point.next.prev = this;
    this.next = point.next;
    this.prev = point;
    point.next = this;
  }

  packTags(concepts, tags) {
    const oldTags = this.tags;
    this.tags = tags.reduce((newTags, tag) => {
      const newTag = new Tag(tag);
      const mappedTags = mapNewTags(oldTags, newTag, concepts);
      newTags.push(...mappedTags);
      return newTags;
    }, []);
  }

  packLinks(concepts, links) {
    const newLinks = [];
    links.forEach((link) => {
      const newLink = new Link(link);
      this.links.forEach((l) => {
        if (newLink.id === l) {
          newLinks.push(newLink);
        }
      });
    });
    this.links = newLinks;
  }

  delete(index, rmCon) {
    const lastChild = this.getLastChild();
    if (lastChild) {
      if (lastChild.next) lastChild.next.prev = this.prev;
      if (this.prev) this.prev.next = lastChild.next;
    } else {
      if (this.next) this.next.prev = this.prev;
      if (this.prev) this.prev.next = this.next;
    }

    this.removeTagRefs(index, rmCon);
  }

  removeTagRefs(index, rmCon) {
    for (let i = 0, l = this.tags.length; i < l; i++) {
      const tag = this.tags[i];
      const concept = tag.concept;
      const indexConcept = concept.tags.indexOf(tag.id);
      concept.tags.splice(indexConcept, 1);
      if (concept.tags.length === 0) rmCon({ index, val: concept.id });
    }

    this.children.forEach((child) => child.removeTagRefs(index, rmCon));
  }

  updateLinkedList(prev) {
    const lastChild = this.getLastChild();
    if (lastChild) {
      this.handleWithLastChild(lastChild, prev);
    } else {
      this.handleWithoutLastChild(prev);
    }
  }

  handleWithLastChild(lastChild, prev) {
    if (this.prev) this.prev.next = lastChild.next;
    if (lastChild.next) lastChild.next.prev = this.prev;
    lastChild.next = prev.next;
    this.prev = prev;
    if (prev.next) prev.next.prev = lastChild;
    prev.next = this;
  }

  handleWithoutLastChild(prev) {
    if (this.prev) this.prev.next = this.next;
    if (this.next) this.next.prev = this.prev;
    this.next = prev.next;
    this.prev = prev;
    if (prev.next) prev.next.prev = this;
    prev.next = this;
  }
}

function mapNewTags(tags, newTag, concepts) {
  return tags.reduce((i, t) => {
    if (newTag.id === t) {
      nestConcept(newTag, concepts);
      i.push(newTag);
    }
    return i;
  }, []);
}

function nestConcept(tag, concepts) {
  const conceptId = tag.concept;
  tag.concept = concepts.find((c) => c.id === conceptId);
  determineLeadTag(tag);
}

function determineLeadTag(tag) {
  if (tag.concept && !tag.concept.lead) {
    if (!tag.concept.anchor) {
      if (tag.id === tag.concept.tags[0]) {
        tag.concept.lead = tag;
      }
    } else if (tag.concept.anchor === tag.id) {
      tag.concept.lead = tag;
    }
  }
}

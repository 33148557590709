// These are all of the messages are steps we display in our client tutorials

import { GEN_DIAGRAM, GEN_DESCRIPTION } from '@/data/constants/constantsMessages';
const figs = `${GEN_DIAGRAM.toLowerCase()}s`;
const spec = `${GEN_DESCRIPTION.toLowerCase()}`;

export default {
  newDocumentSteps: [
    {
      target: '.uploadBox',
      content:
        'Click here to upload a Word document with your claims. You can also drop the file directly into the browser.',
    },
    {
      target: '.blankDocument',
      content: 'Click here to start a blank document.',
    },
  ],

  claimSteps: [
    {
      target: '#claims',
      content: 'Write your claims in this panel.',
      params: {
        enableScrolling: false,
      },
    },
    {
      target: '#simpleClaims',
      content: 'Or you can choose one of these example claim sets.',
    },
    {
      target: '.arrowButton',
      content: "Once you're happy with your claims, click here to generate a draft.",
      params: {
        enableScrolling: false,
      },
    },
  ],

  filledClaimSteps: [
    {
      target: '#claims',
      content: 'Write your claims in this panel.',
      params: {
        enableScrolling: false,
      },
    },
    {
      target: '.arrowButton',
      content: "Once you're happy with your claims, click here to generate a draft.",
      params: {
        enableScrolling: false,
      },
    },
  ],

  generateSteps: [
    {
      target: '#download',
      content: `Click here to download the generated ${figs} and ${spec} as Word, Visio, and PowerPoint files.`,
      params: {
        enableScrolling: false,
      },
    },
    {
      target: '#claims',
      content: 'You can further revise your claims in this panel.',
      params: {
        enableScrolling: false,
      },
    },
    {
      target: '.arrowButton',
      content: `Click here to generate a new set of ${figs} and ${spec} from the revised claims.`,
    },
  ],
};

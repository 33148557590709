<!--
  This component is the main route for rendering editor panels and progress bar
-->

<template>
  <AppLayout>
    <v-fade-transition hide-on-leave>
      <Dashboard v-if="!showGenerate" />
    </v-fade-transition>
    <v-fade-transition hide-on-leave>
      <div class="generateView" v-if="showGenerate">
        <ProgressBar @diagramDownload="diagramDownload = true" />
        <Workspace
          :diagramDownload="diagramDownload"
          @diagramDownloaded="diagramDownload = false"
          ref="workspace"
        />
      </div>
    </v-fade-transition>
  </AppLayout>
</template>

<script>
import 'vue-meta';
import { Workspace, ProgressBar } from '@/components/generate';
import { AppLayout } from '../layouts/';
import { mapGetters, mapMutations, mapState } from 'vuex';
import { DOC_BLANK, DOC_TITLE } from '@/data/constants/constantsMessages';
import { DraftKeys as dk } from '@/data/constants';
import Dashboard from '@/components/generate/Dashboard';

export default {
  name: 'GenerateView',
  data() {
    return {
      diagramDownload: false,
      error: false,
      info: false,
      blank: DOC_BLANK,
      title: DOC_TITLE,
    };
  },
  components: {
    Dashboard,
    Workspace,
    ProgressBar,
    AppLayout,
  },

  created() {
    window.addEventListener('beforeunload', this.closePrompt);
  },

  computed: {
    ...mapState('draft', {
      handledPrompt: (state) => state[dk.HANDLED_PROMPT],
      claims: (state) => state[dk.CLAIMS],
      step: (state) => state[dk.STEP],
    }),
    ...mapGetters('draft', ['validClaims', 'initialOutline', 'showGenerate']),
  },

  watch: {
    showGenerate: {
      handler(val) {
        const { user, patchUserMetadata } = this.$auth;

        if (user && val && this.step === 1 && !user?.metaData.seenTutorialInputs) {
          this.$tours.homeTour.start();
          patchUserMetadata({ draftTutorialClaims: null, seenTutorialInputs: true });
        }
      },
    },
  },

  methods: {
    ...mapMutations('draft', ['resetState', 'setDraft']),

    closePrompt(e) {
      e.preventDefault();
      if (!this.handledPrompt && (this.validClaims || !this.initialOutline)) {
        e.returnValue = '';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.generateView {
  display: flex;
  flex-direction: column;
  padding-top: 48px;
  height: 100vh;
  overflow-y: hidden;
  overflow-x: hidden;
}
.errorAlert {
  position: absolute;
  top: 60px;
  z-index: 10;
  width: 100%;
}
</style>

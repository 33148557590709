import { GenerateHelpers as gh } from '@/support/utilities';
import { formatPara } from '@/support/utilities/unpackerHelpers/descriptionFormatters';
import { DraftKeys as k } from '@/data/constants';
import {
  ACTION_GENERATE_PASS,
  CATEGORY_GENERATE,
} from '@/data/constants/constantsGoogleAnalyticsEvents';
import config from '@/data/var.config.js';
import { TierLimits as tl } from '@/data/constants';

export default {
  async generateDraft(cx, claims = cx.state.claims) {
    try {
      await this.$app.$auth.getUserData();

      const app = this.$app;
      const { month, appData, userData } = gh.unpackSubscriptionData(app);

      if (gh.checkIfSubscribed(appData, userData, month) && config.TIER_CHECKS) {
        gh.showSubscribePrompt(cx);
        return;
      }

      if (gh.notLoading(cx)) {
        const res = await gh.sendRequestToAlice(cx, claims);
        await gh.unpackResponse(res, claims, cx);
      }

      cx.commit('saveGeneratedDraft');

      app.$gtag.event(ACTION_GENERATE_PASS, { event_category: CATEGORY_GENERATE });

      await gh.updateAuth0Data(app.$auth, appData, userData, month);

      gh.logEvents(app, cx);
      gh.saveNewDocumentData(cx.commit);
    } catch (err) {
      gh.handleServerRequestError(err, this.$app.$gtag, cx.commit);
    }
  },

  clearChangedValues({ commit }) {
    commit('setClaimsChanged', false);
    commit('setGraphChanged', false);
  },

  async navigateToSubscribePage({ commit }, params = {}) {
    const router = this.$app.$router;
    const id = this.$app.$auth.user?.sub;

    try {
      commit('setDraftState', { key: k.SHOW_PHRASE, val: false });
      commit('setLoading', true);
      await router.push({ name: 'subscribe', params: { id, ...params } });
    } catch (err) {
      commit('error/handleError', err, { root: true });
    } finally {
      commit('setLoading', false);
    }
  },

  loadDiagrams({ commit, state }, d) {
    const diagrams = [];
    let i = 0;
    return new Promise((resolve) => {
      const interval = setInterval(async () => {
        if (state.clicked) {
          commit('setDiagrams', d);
          clearInterval(interval);
          resolve();
        } else if (state.next) {
          commit('setDraftState', { key: 'next', val: false });
          if (i >= d.length) {
            resolve();
            clearInterval(interval);
            commit('setDraftState', { key: 'next', val: true });
          } else {
            diagrams.push(d[i]);
            commit('setDiagrams', diagrams);
            i++;
          }
        }
      }, 0);
    }).then(() =>
      setTimeout(() => {
        state[k.DIAGRAM_EDITOR_REF].scroll({ y: '100%' });
      }, 50)
    );
  },

  loadClaims({ commit }, claims) {
    commit('changeClaims', '');
    return new Promise((resolve) => {
      setTimeout(() => {
        commit('changeClaims', claims);
        resolve();
      }, 10);
    });
  },

  loadDescription({ commit, state }, doc) {
    commit('changeDescription', '');
    const para = doc.split('\n');

    return new Promise((resolve) => {
      let i = 0;
      const interval = setInterval(() => {
        if (state.clicked) {
          commit('setDescription', doc);
          clearInterval(interval);
          resolve();
        } else if (i === para.length) {
          resolve();
          clearInterval(interval);
          state[k.DESCRIPTION_EDITOR_REF].scroll({ y: '100%' });
        } else {
          const formatPara1 = formatPara(para[i]);
          commit('addParagraph', formatPara1);
          state[k.DESCRIPTION_EDITOR_REF].scroll({ y: '100%' });
          i++;
        }
      }, 50);
    }).then(() =>
      setTimeout(() => {
        state[k.DESCRIPTION_EDITOR_REF].scroll({ y: '100%' });
      }, 50)
    );
  },

  freeTierCheck({ commit }, { cb, message = tl.DEFAULT_TEXT }) {
    if (this.$app.$auth.user.appData.subscribed || !config.TIER_CHECKS) {
      cb();
    } else {
      commit('toggleSubscribePrompt', { show: true, message });
    }
  },
};

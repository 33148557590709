import { v4 as uuid } from 'uuid';

/**
 * Internal link for outline editor
 * @memberOf Internal
 * @property {String} id - UUID string
 * @property {String} text - Link text
 * @property {Number} start - Start position of link
 * @property {Number} end - End position of link
 * @property {String[]} heads - UUID strings of head tags
 * @property {String[]} tails - UUID strings of tail tags
 */
export default class Link {
  constructor(data) {
    this.id = data.id || uuid();
    this.text = data.text;
    this.start = data.start;
    this.end = data.end;
    this.heads = data.head_references || [];
    this.tails = data.tail_references || [];
  }
}

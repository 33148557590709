import state from './state';
import mutations from './mutations';
import getters from './getters';
import actions from './actions';

/**
 * Where everything gets combined and exported for draft Vuex
 */
export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};

<!--
  Renders the different editor panels and handles interactions between them
-->

<template>
  <Scrollable :class-name="`workspace ${showDiagrams ? 'with-query' : ''}`">
    <div class="panels">
      <panel :label="`${GEN_CLAIM}s`" :arrow-button="true" id="claims" @arrowClick="arrowClick">
        <TextEditor
          id="quill-claims"
          v-model="claims"
          ref="claims"
          className="description"
          :event_category="CATEGORY_CLAIMS"
          @focus="claimsEditorFocused = true"
          @blur="claimsEditorFocused = false"
        />
        <div class="example-claims-buttons" v-if="showExampleClaimButtons">
          <p>Click <u>here</u> to start drafting claims. Use <u>tabs</u> to indicate hierarchy.</p>
          <p>Alternatively, try one of these built-in examples:</p>
          <v-btn
            @click="exampleClaims('simpleSystem')"
            depressed
            plain
            :ripple="false"
            height="22"
            id="simpleClaims"
            >simple system claim</v-btn
          >
          <v-btn @click="exampleClaims('simpleMethod')" depressed plain :ripple="false" height="22"
            >simple method claim</v-btn
          >
          <v-btn @click="exampleClaims('complexSystem')" depressed plain :ripple="false" height="22"
            >bicycle power system</v-btn
          >
          <v-btn @click="exampleClaims('complexMethod')" depressed plain :ripple="false" height="22"
            >motion capture</v-btn
          >
        </div>
      </panel>

      <panel
        :label="`${GEN_DIAGRAM}s`"
        v-show="step === 2"
        id="diagramColumn"
        @overflowAmountChanged="diagramOverFlow($event)"
      >
        <div class="diagram-dummy" v-if="diagrams.length === 0" />
        <DiagramEditor
          v-for="(diagram, index) in diagrams"
          :key="index"
          :store="diagram"
          :index="index"
          ref="diagramEditors"
          @numberChanged="handleFigNumberChanged"
          :ignoreFigNumChanges="ignoreFigNumChanges"
        />
      </panel>

      <panel :label="GEN_DESCRIPTION" v-show="step === 2" id="description">
        <TextEditor
          id="quill-description"
          v-model="content"
          className="description"
          ref="description"
          :event_category="CATEGORY_SPEC"
        />
      </panel>
    </div>
  </Scrollable>
</template>

<script>
import DiagramEditor from './DiagramEditor';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import TextEditor from '@/components/generate/TextEditor';
import { GEN_CLAIM, GEN_DESCRIPTION, GEN_DIAGRAM } from '@/data/constants/constantsMessages';
import Panel from './Panel';
import Scrollable from '@/components/global/Scrollable';
import {
  COMPLEX_METHOD,
  COMPLEX_SYSTEM,
  SIMPLE_METHOD,
  SIMPLE_SYSTEM,
} from '@/data/constants/constantsExampleClaims';
import { ALL_HTML_TAGS, DraftKeys as dk } from '@/data/constants';
import { updateFigureNumbers } from '@/support/diagram/FigureUpdater';
import {
  ACTION_CLICK_GENERATE_BUTTON,
  ACTION_USE_MOTION_CAPTURE_METHOD,
  ACTION_USE_SEISMIC_ANCHOR,
  ACTION_USE_SIMPLE_CLAIMS,
  ACTION_USE_SIMPLE_METHOD,
  CATEGORY_CLAIMS,
  CATEGORY_SPEC,
  CATEGORY_GENERATE_TRIGGER,
} from '@/data/constants/constantsGoogleAnalyticsEvents';

export default {
  name: 'Workspace',
  components: {
    Scrollable,
    Panel,
    TextEditor,
    DiagramEditor,
  },
  data() {
    return {
      editorSettings: {
        theme: 'bubble',
        modules: {
          toolbar: false,
        },
        formats: ['indent', 'header', 'bold'],
      },
      claimsEditorFocused: false,
      ignoreFigNumChanges: false,
      editors: [],
      GEN_DIAGRAM,
      GEN_DESCRIPTION,
      GEN_CLAIM,
      CATEGORY_SPEC,
      CATEGORY_CLAIMS,
      show: false,
    };
  },

  computed: {
    ...mapGetters('draft', {
      showDiagrams: 'showDiagrams',
      validClaims: 'validClaims',
    }),
    ...mapState({
      loading: (state) => state.draft[dk.LOADING],
      diagrams: (state) => state.draft[dk.DIAGRAMS],
      description: (state) => state.draft[dk.DESCRIPTION],
      step: (state) => state.draft[dk.STEP],
      inserting: (state) => state.draft[dk.INSERTING],
      viewingTutorial: (state) => state.draft[dk.VIEWING_TUTORIAL],
      sequential: (state) => state.draft[dk.SEQUENTIAL],
    }),

    content: {
      /**
       * @returns {String}
       */
      get() {
        return this.description;
      },

      set(val) {
        this.changeDescription(val);
      },
    },

    claims: {
      /**
       * @returns {String}
       */
      get() {
        return this.$store.state.draft[dk.CLAIMS];
      },

      set(val) {
        this.changeClaims(val);
      },
    },
    /**
     * @returns {Boolean}
     */
    showExampleClaimButtons() {
      const trimmed = this.claims.replace(ALL_HTML_TAGS, '').trim().length;
      return trimmed === 0 && !this.claimsEditorFocused;
    },
  },

  methods: {
    ...mapMutations({
      changeDescription: 'draft/changeDescription',
      changeClaims: 'draft/changeClaims',
      setClaims: 'draft/setClaims',
      setErrorState: 'error/setErrorState',
      toggleTooltip: 'tooltip/toggleTooltip',
      setDraftState: 'draft/setDraftState',
    }),

    ...mapActions('draft', ['generateDraft']),

    async arrowClick() {
      try {
        this.$gtag.event(ACTION_CLICK_GENERATE_BUTTON, {
          event_category: CATEGORY_GENERATE_TRIGGER,
        });

        this.setDraftState({ key: dk.SEQUENTIAL, val: true });
        await this.generateDraft();
        this.setDraftState({ key: dk.SEQUENTIAL, val: false });
      } catch (err) {
        console.error(err);
      }
    },

    async handleFigNumberChanged(fig) {
      this.ignoreFigNumChanges = true;
      const newSpec = await updateFigureNumbers(fig);
      this.changeDescription(newSpec);
      this.ignoreFigNumChanges = false;
      this.$refs.diagramEditors.forEach(async (component) => {
        await component.renderDiagramFromStore();
      });
    },

    diagramOverFlow(e) {
      if (this.sequential) {
        e.instance?.scroll({ y: '100%' });
      }
    },

    exampleClaims(claims = 'simpleSystem') {
      if (claims === 'complexSystem') {
        this.$gtag.event(ACTION_USE_SEISMIC_ANCHOR, { event_category: CATEGORY_CLAIMS });
        this.setClaims(COMPLEX_SYSTEM);
        return;
      }
      if (claims === 'simpleSystem') {
        this.$gtag.event(ACTION_USE_SIMPLE_CLAIMS, { event_category: CATEGORY_CLAIMS });
        this.setClaims(SIMPLE_SYSTEM);
        return;
      }
      if (claims === 'simpleMethod') {
        this.$gtag.event(ACTION_USE_SIMPLE_METHOD, { event_category: CATEGORY_CLAIMS });
        this.setClaims(SIMPLE_METHOD);
        return;
      }
      if (claims === 'complexMethod') {
        this.$gtag.event(ACTION_USE_MOTION_CAPTURE_METHOD, { event_category: CATEGORY_CLAIMS });
        this.setClaims(COMPLEX_METHOD);
      }
    },

    mapEditorTextToArray(contentArray) {
      const textData = [];
      contentArray.forEach((content) => {
        if (content.nodeName === 'OL' || content.nodeName === 'UL') {
          const contentChildren = Array.prototype.slice.call(content.children);
          contentChildren.forEach((child, index) => {
            textData.push(`${index + 1}. ${child.textContent.trim().toLowerCase()}`);
          });
        } else {
          textData.push(content.textContent.trim());
        }
      });
      return textData;
    },

    handleTextChange(e) {
      console.log(e);
    },
  },

  watch: {
    step: {
      immediate: true,
      handler(step) {
        step === 2
          ? setTimeout(() => {
              this.show = true;
            }, 500)
          : (this.show = false);
      },
    },

    description: {
      handler() {
        const sel = document.getSelection();
        const parent = sel.anchorNode.parentNode;

        if (parent.nodeName === 'SPAN') {
          const text = sel.anchorNode;
          const range = sel.getRangeAt(0);
          const offset = sel.anchorOffset;
          range.setStartBefore(parent);
          range.setEndAfter(parent);
          range.deleteContents();
          range.insertNode(text);
          range.setStart(text, offset);
          range.collapse(true);
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../scss/variables.scss';

$screen-top-height: 140px;
$panel-label-height: 30px;
$panel-aspect-ratio: 8.5/11;
$total-panels: 3;

$wrapper-height: calc(((100vw - 250px) / #{$panel-aspect-ratio}) + #{$panel-label-height});
$wrapper-max-height: calc(100vh - #{$screen-top-height});
$wrapper-max-width: calc(
  ((100vh - #{$screen-top-height}) - #{$panel-label-height}) * #{$panel-aspect-ratio}
);
$tri-panel-height: calc(
  (100vw - #{$screen-top-height}) / #{$total-panels} / #{$panel-aspect-ratio}
);

.example-claims-buttons {
  position: absolute;
  pointer-events: none;
  color: $gray;
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 20px;
  width: 100%;
  height: 100%;
  top: 0;
  font-family: Calibri, sans-serif !important;
  .v-btn {
    font-family: Calibri, sans-serif !important;
    pointer-events: auto;
    justify-self: center !important;
    margin-bottom: 15px;
    margin-left: 20px;
    background-color: $light-gray !important;
    color: $strong-gray !important;
    transition: color 0.1s, background-color 0.1s !important;
    padding: 0 5px !important;
    &:hover {
      background-color: $strong-gray !important;
      color: $white !important;
    }
  }

  div {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 80%;
    justify-content: center;
    align-items: center;
  }
}

.workspace {
  position: relative;
  z-index: 1;
  display: flex !important;
  transition: all 0.3s;
  height: 100%;
  width: 100%;
  padding: 0 10px 20px 10px;
  .panels {
    display: flex;
    justify-content: center;

    // center
    margin: auto;

    // Limiting width
    width: calc(100vw - 250px);
    height: $wrapper-height;

    // limiting height
    max-height: $wrapper-max-height;
    max-width: $wrapper-max-width;

    min-width: 300px;
    min-height: calc(300px * (11 / 8));

    &::v-deep .panel {
      width: 100%;
      height: 100%;
      position: relative;
    }
  }

  &.with-query {
    .panels {
      width: 100vw;
      height: calc((100vw - 10px) / 3 / (8.5 / 11));
      max-height: calc(100vh - 140px);
      max-width: calc((100vh - 140px) * (8.5 / 11) * 3 + 10px);
      min-width: 800px;
      min-height: calc(800px * (440 / 1010));

      // Child panels
      &::v-deep .panel {
        width: calc(100% / 3);
        height: 100%;
        margin: 0 10px;
        position: relative;
      }
    }
  }
}

.diagram-dummy {
  background-color: #fff;
  height: 100%;
  width: 100%;
}

.overlayText {
  font-size: 20px;
}
</style>

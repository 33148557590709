import Docxtemplater from 'docxtemplater';
import PizZip from 'pizzip';
import PizZipUtils from 'pizzip/utils/index.js';
import { saveAs } from 'save-as';
import { ALL_HTML_TAGS, HTMLEndTags } from '@/data/constants';
import { fileDate, titleCase } from '@/support/utilities';

const MIME_TYPE = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
const GENERATE_TYPE = 'blob';

export function renderDoc(description) {
  loadFile(`./patentTemplateV3.docx`, function (error, content) {
    if (error) {
      throw error;
    }
    const zip = new PizZip(content);
    const doc = new Docxtemplater(zip, {
      paragraphLoop: true,
      linebreaks: true,
    });

    const spans = description.split(HTMLEndTags);
    const data = packDocument(spans);

    doc.render(data);

    const out = doc.getZip().generate({
      type: GENERATE_TYPE,
      mimeType: MIME_TYPE,
    });

    saveAs(out, `${fileDate()} - ${titleCase(data.title)} - Spec.docx`);
  });
}

function loadFile(url, callback) {
  PizZipUtils.getBinaryContent(url, callback);
}

function packDocument(spans) {
  return spans.reduce(
    (data, span, index) => {
      if (span.includes('h1') && !data.hasClaims) {
        data.title = span.replace(ALL_HTML_TAGS, '');
      }

      if (span.includes('<h2>') && span.includes('claims')) {
        data.hasClaims = true;
      }

      if (span.includes('<h2>') && !data.hasClaims) {
        const section = {
          heading: span.replace(ALL_HTML_TAGS, '').toUpperCase(),
          paragraphs: packParagraphs(spans, index),
        };
        data.docSections.push(section);
      }

      if (span.replace(ALL_HTML_TAGS, '').match(/^\s*\(?\d+.?\)?/)) {
        const claim = {
          claim: span.replace(ALL_HTML_TAGS, '').replace(/\s*\(?\d.?\)?/, ''),
          children: packClaimChildren(spans, index),
        };

        data.claims.push(claim);
      }

      return data;
    },
    { docSections: [], title: '', claims: [], hasClaims: false }
  );
}

function packParagraphs(spans, start) {
  const paragraphs = [];
  for (let i = start + 1, l = spans.length; i < l; i++) {
    const span = spans[i];
    if (span.includes('<h2>')) {
      break;
    }
    if (span.trim().length > 0) paragraphs.push({ text: span.replace(ALL_HTML_TAGS, '') });
  }
  return paragraphs;
}

function packClaimChildren(spans, start) {
  const children = [];
  for (let i = start + 1, l = spans.length; i < l; i++) {
    const span = spans[i];
    if (span.replace(ALL_HTML_TAGS, '').match(/^\s*\(?\d+.?\)?/) || span.includes('<h2>')) {
      break;
    }
    if (span.trim().length > 0) children.push({ text: span.replace(ALL_HTML_TAGS, '') });
  }
  return children;
}

<template>
  <v-snackbar
    :color="color"
    v-model="show"
    @input="$emit('input', $event)"
    top
    :text="text"
    :absolute="absolute"
  >
    <span v-html="message" />
    <template v-slot:action="{ attrs }">
      <v-btn icon v-bind="attrs" @click="$emit('close', $event)">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: 'Message',
  props: {
    value: Boolean,
    message: String,
    color: String,
    text: {
      type: Boolean,
      default: true,
      required: false,
    },
    absolute: {
      type: Boolean,
      default: false,
      required: false,
    },
  },

  computed: {
    show: {
      /**
       * @returns {Boolean}
       */
      get() {
        return this.value;
      },

      set(val) {
        this.$emit('input', val);
      },
    },
  },
};
</script>

<style scoped></style>

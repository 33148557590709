<template>
  <AuthenticationLayout
    :loading="loading"
    @submit="login"
    :error="error.value"
    :errorMessage="error.message"
    @closeError="error.value = false"
  >
    <v-text-field
      v-model="email"
      label="Email"
      solo
      color="blue lighten-3"
      height="50"
      :rules="[rules.required]"
      @keyup.enter.prevent="login"
    />
    <PasswordInput v-model="password" @pressedEnter="login" :validate="false" />
    <v-dialog v-model="emailResetToggle" width="450">
      <template v-slot:activator="{ on, attrs }">
        <button
          type="button"
          class="align-self-end mt-n7 mb-5 text-decoration-underline primary--text passwordButton caption"
          v-bind="attrs"
          v-on="on"
        >
          {{ forgot }}
        </button>
      </template>

      <v-card class="pa-10">
        <v-form ref="resetForm" class="d-flex flex-column">
          <v-text-field
            v-model="resetEmail"
            label="Enter your email"
            width
            outlined
            :rules="[rules.required, rules.email]"
          ></v-text-field>
          <v-btn @click="resetPassword" color="primary" :loading="passwordLoading">
            {{ reset }}
          </v-btn>
        </v-form>
      </v-card>
    </v-dialog>
  </AuthenticationLayout>
</template>

<script>
import { AuthenticationLayout } from '../layouts';
import { postToAuth0 } from '@/support/alice';
import { AUTH_CLIENT_ID, REALM } from '@/data/var.config';
import { EMAIL_REGEX } from '@/data/constants';
import { FORM_RESET, FORM_FORGOT } from '@/data/constants/constantsMessages';
import PasswordInput from '@/components/signup/PasswordInput';

export default {
  name: 'Login',
  components: {
    PasswordInput,
    AuthenticationLayout,
  },
  data() {
    return {
      email: '',
      password: '',
      emailResetToggle: false,
      resetEmail: '',
      passwordLoading: false,
      loading: false,
      snackbar: false,
      rememberEmail: true,
      error: {
        value: false,
        message: '',
      },
      rules: {
        required: (val) => !!val || 'Required',
        email: (email) => {
          return EMAIL_REGEX.test(email) || 'Invalid e-mail';
        },
      },
      reset: FORM_RESET,
      forgot: FORM_FORGOT,
    };
  },
  metaInfo: {
    title: 'Log In',
  },

  mounted() {
    if (process.env.VUE_APP_LOGIN_DOMAIN === 'patentpal.us.auth0.com') {
      this.$auth.universalLogin();
    }

    if (this.$route.query.reset) {
      this.emailResetToggle = true;
    }

    const storage = window.localStorage;
    const email = storage.getItem('rememberedEmail');
    const remember = storage.getItem('rememberMe');
    if (email) {
      this.email = email;
      this.rememberEmail = true;
    }

    if (remember && remember !== 'true') {
      this.rememberEmail = false;
    }
  },

  watch: {
    email: {
      handler(val) {
        this.resetEmail = val;
      },
    },
  },

  methods: {
    async login() {
      this.loading = true;
      const storage = window.localStorage;
      if (this.rememberEmail) {
        storage.setItem('rememberedEmail', this.email);
        storage.setItem('rememberMe', 'true');
      } else {
        storage.removeItem('rememberedEmail');
        storage.setItem('rememberMe', 'false');
      }
      try {
        await this.$auth.login(this.email, this.password);
        this.loading = false;
      } catch (err) {
        console.error(err);
        this.error.value = true;
        this.error.message = err.description;
        this.snackbar = true;
        this.loading = false;
      }
    },

    async resetPassword() {
      if (this.$refs.resetForm.validate()) {
        try {
          this.passwordLoading = true;
          await postToAuth0('dbconnections/change_password', {
            client_id: AUTH_CLIENT_ID,
            email: this.resetEmail,
            connection: REALM,
          });
          this.passwordLoading = false;
          this.emailResetToggle = false;
          this.toggleSnackbar(
            true,
            `We've sent an email to ${this.resetEmail}. Please check your inbox to reset your password.`
          );
        } catch (err) {
          this.passwordLoading = false;
          this.error.value = true;
          this.error.message =
            'We were unable to send you a password Reset email, Please contact support@patentpal.com';
          this.loading = false;
        }
      }
    },

    toggleSnackbar(succeeded, message) {
      this.error.value = false;
      this.error.message = message;
      this.snackbar = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.passwordButton {
  margin-right: 12px;
  position: relative;
  z-index: 2;
}
</style>

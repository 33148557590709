import { ErrorKeys as K, messages as M } from '@/data/constants';
import { formatErrorMessage } from '@/support/utilities';

export default {
  setErrorState(state, { key, val, message = M.ERROR_APP }) {
    if (val === true) state[K.MESSAGE] = message;
    state[key] = val;
  },

  handleError(state, err) {
    const res = err.response;
    let message;

    if (res?.status === K.USER_ERROR) {
      message = formatErrorMessage(res.data.message);
    } else {
      message = formatErrorMessage(M.ERROR_APP, res?.data.id);
    }
    console.error(err);
    state[K.MESSAGE] = message;
    state[K.ERROR] = true;
  },
};

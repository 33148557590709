// These are keys used in the vuex upload state module.
// This was an experiment to try and reduce the amount of work
// it would take to change a key in the state object.
// In practice it has actually been difficult to maintain due to
// needing to import this anywhere we want to access state

export const FILE = 'file';
export const UPLOADING = 'uploading';
export const PROGRESS = 'progress';
export const DROP = 'drop';
export const DIALOG = 'dialog';
export const OVERWRITE = 'overwrite';
export const MODULE_NAME = 'upload';

export const TEMP_INPUT = 'tempInput';

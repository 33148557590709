<template>
  <div class="container" id="uploadContainer">
    <input
      type="file"
      id="fileUpload"
      class="inputfile"
      accept=".doc, .docx"
      @change="handleChange"
    />
    <label for="fileUpload" class="box uploadBox">
      <img src="@/data/static/Word Icon.svg" alt="Upload a word document" class="wordIcon" />
    </label>
    <p>{{ displayText }}</p>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import { DOC_UPLOAD } from '@/data/constants/constantsMessages';
import { ACTION_DASHBOARD_SELECT_UPLOAD } from '@/data/constants/constantsGoogleAnalyticsEvents';

export default {
  name: 'UploadFileInput',
  props: {
    clickable: Boolean,
    text: String,
  },

  methods: {
    ...mapMutations({
      saveFile: 'upload/saveFile',
    }),

    handleChange(e) {
      this.saveFile({ e, action: ACTION_DASHBOARD_SELECT_UPLOAD });
    },
  },

  computed: {
    /**
     * Checks if text is defined or not. If it is will render the provided text,
     * otherwise will render default text.
     * @returns {String} string of text to render in UI
     */
    displayText() {
      return this.text ? this.text : DOC_UPLOAD;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/variables';

p {
  margin: 32px 0px;
  color: $dark-gray;
}

.container {
  width: 100%;
  margin: 0;
  padding: 0;
  text-align: center;
}

.wordIcon {
  position: absolute;
  z-index: 1;
}

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  background-color: #fff;
  width: 180px;
  //height: 300px;
  aspect-ratio: 8.5/11;
  border-radius: 5px;
  outline: none;
  border: none;
  cursor: pointer;
  margin: 0 20px;
  max-width: 100% !important;
  &:hover {
    background-color: #f6f6f6;
  }
  &.drop {
    cursor: initial;
    &:hover {
      background-color: #fff;
    }
  }
  .hidden {
    opacity: 0;
    position: absolute;
  }
}
</style>

// This is used in our subscribe form in the countries selection input

export default [
  {
    country_name: 'Afghanistan',
    country_code: 'AF',
    id: 1,
  },
  {
    country_name: 'Aland Islands',
    country_code: 'AX',
    id: 2,
  },
  {
    country_name: 'Albania',
    country_code: 'AL',
    id: 3,
  },
  {
    country_name: 'Algeria',
    country_code: 'DZ',
    id: 4,
  },
  {
    country_name: 'American Samoa',
    country_code: 'AS',
    id: 5,
  },
  {
    country_name: 'Andorra',
    country_code: 'AD',
    id: 6,
  },
  {
    country_name: 'Angola',
    country_code: 'AO',
    id: 7,
  },
  {
    country_name: 'Anguilla',
    country_code: 'AI',
    id: 8,
  },
  {
    country_name: 'Antarctica',
    country_code: 'AQ',
    id: 9,
  },
  {
    country_name: 'Antigua and Barbuda',
    country_code: 'AG',
    id: 10,
  },
  {
    country_name: 'Argentina',
    country_code: 'AR',
    id: 11,
  },
  {
    country_name: 'Armenia',
    country_code: 'AM',
    id: 12,
  },
  {
    country_name: 'Aruba',
    country_code: 'AW',
    id: 13,
  },
  {
    country_name: 'Australia',
    country_code: 'AU',
    id: 14,
  },
  {
    country_name: 'Austria',
    country_code: 'AT',
    id: 15,
  },
  {
    country_name: 'Azerbaijan',
    country_code: 'AZ',
    id: 16,
  },
  {
    country_name: 'Bahamas',
    country_code: 'BS',
    id: 17,
  },
  {
    country_name: 'Bahrain',
    country_code: 'BH',
    id: 18,
  },
  {
    country_name: 'Bangladesh',
    country_code: 'BD',
    id: 19,
  },
  {
    country_name: 'Barbados',
    country_code: 'BB',
    id: 20,
  },
  {
    country_name: 'Belarus',
    country_code: 'BY',
    id: 21,
  },
  {
    country_name: 'Belgium',
    country_code: 'BE',
    id: 22,
  },
  {
    country_name: 'Belize',
    country_code: 'BZ',
    id: 23,
  },
  {
    country_name: 'Benin',
    country_code: 'BJ',
    id: 24,
  },
  {
    country_name: 'Bermuda',
    country_code: 'BM',
    id: 25,
  },
  {
    country_name: 'Bhutan',
    country_code: 'BT',
    id: 26,
  },
  {
    country_name: 'Bolivia',
    country_code: 'BO',
    id: 27,
  },
  {
    country_name: 'Bonaire, Sint Eustatius and Saba',
    country_code: 'BQ',
    id: 28,
  },
  {
    country_name: 'Bosnia and Herzegovina',
    country_code: 'BA',
    id: 29,
  },
  {
    country_name: 'Botswana',
    country_code: 'BW',
    id: 30,
  },
  {
    country_name: 'Bouvet Island',
    country_code: 'BV',
    id: 31,
  },
  {
    country_name: 'Brazil',
    country_code: 'BR',
    id: 32,
  },
  {
    country_name: 'British Indian Ocean Territory',
    country_code: 'IO',
    id: 33,
  },
  {
    country_name: 'Brunei Darussalam',
    country_code: 'BN',
    id: 34,
  },
  {
    country_name: 'Bulgaria',
    country_code: 'BG',
    id: 35,
  },
  {
    country_name: 'Burkina Faso',
    country_code: 'BF',
    id: 36,
  },
  {
    country_name: 'Burundi',
    country_code: 'BI',
    id: 37,
  },
  {
    country_name: 'Cambodia',
    country_code: 'KH',
    id: 38,
  },
  {
    country_name: 'Cameroon',
    country_code: 'CM',
    id: 39,
  },
  {
    country_name: 'Canada',
    country_code: 'CA',
    id: 40,
  },
  {
    country_name: 'Cape Verde',
    country_code: 'CV',
    id: 41,
  },
  {
    country_name: 'Cayman Islands',
    country_code: 'KY',
    id: 42,
  },
  {
    country_name: 'Central African Republic',
    country_code: 'CF',
    id: 43,
  },
  {
    country_name: 'Chad',
    country_code: 'TD',
    id: 44,
  },
  {
    country_name: 'Chile',
    country_code: 'CL',
    id: 45,
  },
  {
    country_name: 'China',
    country_code: 'CN',
    id: 46,
  },
  {
    country_name: 'Christmas Island',
    country_code: 'CX',
    id: 47,
  },
  {
    country_name: 'Cocos (Keeling) Islands',
    country_code: 'CC',
    id: 48,
  },
  {
    country_name: 'Colombia',
    country_code: 'CO',
    id: 49,
  },
  {
    country_name: 'Comoros',
    country_code: 'KM',
    id: 50,
  },
  {
    country_name: 'Congo',
    country_code: 'CG',
    id: 51,
  },
  {
    country_name: 'Congo, Democratic Republic of the Congo',
    country_code: 'CD',
    id: 52,
  },
  {
    country_name: 'Cook Islands',
    country_code: 'CK',
    id: 53,
  },
  {
    country_name: 'Costa Rica',
    country_code: 'CR',
    id: 54,
  },
  {
    country_name: "Cote D'Ivoire",
    country_code: 'CI',
    id: 55,
  },
  {
    country_name: 'Croatia',
    country_code: 'HR',
    id: 56,
  },
  {
    country_name: 'Cuba',
    country_code: 'CU',
    id: 57,
  },
  {
    country_name: 'Curacao',
    country_code: 'CW',
    id: 58,
  },
  {
    country_name: 'Cyprus',
    country_code: 'CY',
    id: 59,
  },
  {
    country_name: 'Czech Republic',
    country_code: 'CZ',
    id: 60,
  },
  {
    country_name: 'Denmark',
    country_code: 'DK',
    id: 61,
  },
  {
    country_name: 'Djibouti',
    country_code: 'DJ',
    id: 62,
  },
  {
    country_name: 'Dominica',
    country_code: 'DM',
    id: 63,
  },
  {
    country_name: 'Dominican Republic',
    country_code: 'DO',
    id: 64,
  },
  {
    country_name: 'Ecuador',
    country_code: 'EC',
    id: 65,
  },
  {
    country_name: 'Egypt',
    country_code: 'EG',
    id: 66,
  },
  {
    country_name: 'El Salvador',
    country_code: 'SV',
    id: 67,
  },
  {
    country_name: 'Equatorial Guinea',
    country_code: 'GQ',
    id: 68,
  },
  {
    country_name: 'Eritrea',
    country_code: 'ER',
    id: 69,
  },
  {
    country_name: 'Estonia',
    country_code: 'EE',
    id: 70,
  },
  {
    country_name: 'Ethiopia',
    country_code: 'ET',
    id: 71,
  },
  {
    country_name: 'Falkland Islands (Malvinas)',
    country_code: 'FK',
    id: 72,
  },
  {
    country_name: 'Faroe Islands',
    country_code: 'FO',
    id: 73,
  },
  {
    country_name: 'Fiji',
    country_code: 'FJ',
    id: 74,
  },
  {
    country_name: 'Finland',
    country_code: 'FI',
    id: 75,
  },
  {
    country_name: 'France',
    country_code: 'FR',
    id: 76,
  },
  {
    country_name: 'French Guiana',
    country_code: 'GF',
    id: 77,
  },
  {
    country_name: 'French Polynesia',
    country_code: 'PF',
    id: 78,
  },
  {
    country_name: 'French Southern Territories',
    country_code: 'TF',
    id: 79,
  },
  {
    country_name: 'Gabon',
    country_code: 'GA',
    id: 80,
  },
  {
    country_name: 'Gambia',
    country_code: 'GM',
    id: 81,
  },
  {
    country_name: 'Georgia',
    country_code: 'GE',
    id: 82,
  },
  {
    country_name: 'Germany',
    country_code: 'DE',
    id: 83,
  },
  {
    country_name: 'Ghana',
    country_code: 'GH',
    id: 84,
  },
  {
    country_name: 'Gibraltar',
    country_code: 'GI',
    id: 85,
  },
  {
    country_name: 'Greece',
    country_code: 'GR',
    id: 86,
  },
  {
    country_name: 'Greenland',
    country_code: 'GL',
    id: 87,
  },
  {
    country_name: 'Grenada',
    country_code: 'GD',
    id: 88,
  },
  {
    country_name: 'Guadeloupe',
    country_code: 'GP',
    id: 89,
  },
  {
    country_name: 'Guam',
    country_code: 'GU',
    id: 90,
  },
  {
    country_name: 'Guatemala',
    country_code: 'GT',
    id: 91,
  },
  {
    country_name: 'Guernsey',
    country_code: 'GG',
    id: 92,
  },
  {
    country_name: 'Guinea',
    country_code: 'GN',
    id: 93,
  },
  {
    country_name: 'Guinea-Bissau',
    country_code: 'GW',
    id: 94,
  },
  {
    country_name: 'Guyana',
    country_code: 'GY',
    id: 95,
  },
  {
    country_name: 'Haiti',
    country_code: 'HT',
    id: 96,
  },
  {
    country_name: 'Heard Island and Mcdonald Islands',
    country_code: 'HM',
    id: 97,
  },
  {
    country_name: 'Holy See (Vatican City State)',
    country_code: 'VA',
    id: 98,
  },
  {
    country_name: 'Honduras',
    country_code: 'HN',
    id: 99,
  },
  {
    country_name: 'Hong Kong',
    country_code: 'HK',
    id: 100,
  },
  {
    country_name: 'Hungary',
    country_code: 'HU',
    id: 101,
  },
  {
    country_name: 'Iceland',
    country_code: 'IS',
    id: 102,
  },
  {
    country_name: 'India',
    country_code: 'IN',
    id: 103,
  },
  {
    country_name: 'Indonesia',
    country_code: 'ID',
    id: 104,
  },
  {
    country_name: 'Iran, Islamic Republic of',
    country_code: 'IR',
    id: 105,
  },
  {
    country_name: 'Iraq',
    country_code: 'IQ',
    id: 106,
  },
  {
    country_name: 'Ireland',
    country_code: 'IE',
    id: 107,
  },
  {
    country_name: 'Isle of Man',
    country_code: 'IM',
    id: 108,
  },
  {
    country_name: 'Israel',
    country_code: 'IL',
    id: 109,
  },
  {
    country_name: 'Italy',
    country_code: 'IT',
    id: 110,
  },
  {
    country_name: 'Jamaica',
    country_code: 'JM',
    id: 111,
  },
  {
    country_name: 'Japan',
    country_code: 'JP',
    id: 112,
  },
  {
    country_name: 'Jersey',
    country_code: 'JE',
    id: 113,
  },
  {
    country_name: 'Jordan',
    country_code: 'JO',
    id: 114,
  },
  {
    country_name: 'Kazakhstan',
    country_code: 'KZ',
    id: 115,
  },
  {
    country_name: 'Kenya',
    country_code: 'KE',
    id: 116,
  },
  {
    country_name: 'Kiribati',
    country_code: 'KI',
    id: 117,
  },
  {
    country_name: "Korea, Democratic People's Republic of",
    country_code: 'KP',
    id: 118,
  },
  {
    country_name: 'Korea, Republic of',
    country_code: 'KR',
    id: 119,
  },
  {
    country_name: 'Kosovo',
    country_code: 'XK',
    id: 120,
  },
  {
    country_name: 'Kuwait',
    country_code: 'KW',
    id: 121,
  },
  {
    country_name: 'Kyrgyzstan',
    country_code: 'KG',
    id: 122,
  },
  {
    country_name: "Lao People's Democratic Republic",
    country_code: 'LA',
    id: 123,
  },
  {
    country_name: 'Latvia',
    country_code: 'LV',
    id: 124,
  },
  {
    country_name: 'Lebanon',
    country_code: 'LB',
    id: 125,
  },
  {
    country_name: 'Lesotho',
    country_code: 'LS',
    id: 126,
  },
  {
    country_name: 'Liberia',
    country_code: 'LR',
    id: 127,
  },
  {
    country_name: 'Libyan Arab Jamahiriya',
    country_code: 'LY',
    id: 128,
  },
  {
    country_name: 'Liechtenstein',
    country_code: 'LI',
    id: 129,
  },
  {
    country_name: 'Lithuania',
    country_code: 'LT',
    id: 130,
  },
  {
    country_name: 'Luxembourg',
    country_code: 'LU',
    id: 131,
  },
  {
    country_name: 'Macao',
    country_code: 'MO',
    id: 132,
  },
  {
    country_name: 'Macedonia, the Former Yugoslav Republic of',
    country_code: 'MK',
    id: 133,
  },
  {
    country_name: 'Madagascar',
    country_code: 'MG',
    id: 134,
  },
  {
    country_name: 'Malawi',
    country_code: 'MW',
    id: 135,
  },
  {
    country_name: 'Malaysia',
    country_code: 'MY',
    id: 136,
  },
  {
    country_name: 'Maldives',
    country_code: 'MV',
    id: 137,
  },
  {
    country_name: 'Mali',
    country_code: 'ML',
    id: 138,
  },
  {
    country_name: 'Malta',
    country_code: 'MT',
    id: 139,
  },
  {
    country_name: 'Marshall Islands',
    country_code: 'MH',
    id: 140,
  },
  {
    country_name: 'Martinique',
    country_code: 'MQ',
    id: 141,
  },
  {
    country_name: 'Mauritania',
    country_code: 'MR',
    id: 142,
  },
  {
    country_name: 'Mauritius',
    country_code: 'MU',
    id: 143,
  },
  {
    country_name: 'Mayotte',
    country_code: 'YT',
    id: 144,
  },
  {
    country_name: 'Mexico',
    country_code: 'MX',
    id: 145,
  },
  {
    country_name: 'Micronesia, Federated States of',
    country_code: 'FM',
    id: 146,
  },
  {
    country_name: 'Moldova, Republic of',
    country_code: 'MD',
    id: 147,
  },
  {
    country_name: 'Monaco',
    country_code: 'MC',
    id: 148,
  },
  {
    country_name: 'Mongolia',
    country_code: 'MN',
    id: 149,
  },
  {
    country_name: 'Montenegro',
    country_code: 'ME',
    id: 150,
  },
  {
    country_name: 'Montserrat',
    country_code: 'MS',
    id: 151,
  },
  {
    country_name: 'Morocco',
    country_code: 'MA',
    id: 152,
  },
  {
    country_name: 'Mozambique',
    country_code: 'MZ',
    id: 153,
  },
  {
    country_name: 'Myanmar',
    country_code: 'MM',
    id: 154,
  },
  {
    country_name: 'Namibia',
    country_code: 'NA',
    id: 155,
  },
  {
    country_name: 'Nauru',
    country_code: 'NR',
    id: 156,
  },
  {
    country_name: 'Nepal',
    country_code: 'NP',
    id: 157,
  },
  {
    country_name: 'Netherlands',
    country_code: 'NL',
    id: 158,
  },
  {
    country_name: 'Netherlands Antilles',
    country_code: 'AN',
    id: 159,
  },
  {
    country_name: 'New Caledonia',
    country_code: 'NC',
    id: 160,
  },
  {
    country_name: 'New Zealand',
    country_code: 'NZ',
    id: 161,
  },
  {
    country_name: 'Nicaragua',
    country_code: 'NI',
    id: 162,
  },
  {
    country_name: 'Niger',
    country_code: 'NE',
    id: 163,
  },
  {
    country_name: 'Nigeria',
    country_code: 'NG',
    id: 164,
  },
  {
    country_name: 'Niue',
    country_code: 'NU',
    id: 165,
  },
  {
    country_name: 'Norfolk Island',
    country_code: 'NF',
    id: 166,
  },
  {
    country_name: 'Northern Mariana Islands',
    country_code: 'MP',
    id: 167,
  },
  {
    country_name: 'Norway',
    country_code: 'NO',
    id: 168,
  },
  {
    country_name: 'Oman',
    country_code: 'OM',
    id: 169,
  },
  {
    country_name: 'Pakistan',
    country_code: 'PK',
    id: 170,
  },
  {
    country_name: 'Palau',
    country_code: 'PW',
    id: 171,
  },
  {
    country_name: 'Palestinian Territory, Occupied',
    country_code: 'PS',
    id: 172,
  },
  {
    country_name: 'Panama',
    country_code: 'PA',
    id: 173,
  },
  {
    country_name: 'Papua New Guinea',
    country_code: 'PG',
    id: 174,
  },
  {
    country_name: 'Paraguay',
    country_code: 'PY',
    id: 175,
  },
  {
    country_name: 'Peru',
    country_code: 'PE',
    id: 176,
  },
  {
    country_name: 'Philippines',
    country_code: 'PH',
    id: 177,
  },
  {
    country_name: 'Pitcairn',
    country_code: 'PN',
    id: 178,
  },
  {
    country_name: 'Poland',
    country_code: 'PL',
    id: 179,
  },
  {
    country_name: 'Portugal',
    country_code: 'PT',
    id: 180,
  },
  {
    country_name: 'Puerto Rico',
    country_code: 'PR',
    id: 181,
  },
  {
    country_name: 'Qatar',
    country_code: 'QA',
    id: 182,
  },
  {
    country_name: 'Reunion',
    country_code: 'RE',
    id: 183,
  },
  {
    country_name: 'Romania',
    country_code: 'RO',
    id: 184,
  },
  {
    country_name: 'Russian Federation',
    country_code: 'RU',
    id: 185,
  },
  {
    country_name: 'Rwanda',
    country_code: 'RW',
    id: 186,
  },
  {
    country_name: 'Saint Barthelemy',
    country_code: 'BL',
    id: 187,
  },
  {
    country_name: 'Saint Helena',
    country_code: 'SH',
    id: 188,
  },
  {
    country_name: 'Saint Kitts and Nevis',
    country_code: 'KN',
    id: 189,
  },
  {
    country_name: 'Saint Lucia',
    country_code: 'LC',
    id: 190,
  },
  {
    country_name: 'Saint Martin',
    country_code: 'MF',
    id: 191,
  },
  {
    country_name: 'Saint Pierre and Miquelon',
    country_code: 'PM',
    id: 192,
  },
  {
    country_name: 'Saint Vincent and the Grenadines',
    country_code: 'VC',
    id: 193,
  },
  {
    country_name: 'Samoa',
    country_code: 'WS',
    id: 194,
  },
  {
    country_name: 'San Marino',
    country_code: 'SM',
    id: 195,
  },
  {
    country_name: 'Sao Tome and Principe',
    country_code: 'ST',
    id: 196,
  },
  {
    country_name: 'Saudi Arabia',
    country_code: 'SA',
    id: 197,
  },
  {
    country_name: 'Senegal',
    country_code: 'SN',
    id: 198,
  },
  {
    country_name: 'Serbia',
    country_code: 'RS',
    id: 199,
  },
  {
    country_name: 'Serbia and Montenegro',
    country_code: 'CS',
    id: 200,
  },
  {
    country_name: 'Seychelles',
    country_code: 'SC',
    id: 201,
  },
  {
    country_name: 'Sierra Leone',
    country_code: 'SL',
    id: 202,
  },
  {
    country_name: 'Singapore',
    country_code: 'SG',
    id: 203,
  },
  {
    country_name: 'Sint Maarten',
    country_code: 'SX',
    id: 204,
  },
  {
    country_name: 'Slovakia',
    country_code: 'SK',
    id: 205,
  },
  {
    country_name: 'Slovenia',
    country_code: 'SI',
    id: 206,
  },
  {
    country_name: 'Solomon Islands',
    country_code: 'SB',
    id: 207,
  },
  {
    country_name: 'Somalia',
    country_code: 'SO',
    id: 208,
  },
  {
    country_name: 'South Africa',
    country_code: 'ZA',
    id: 209,
  },
  {
    country_name: 'South Georgia and the South Sandwich Islands',
    country_code: 'GS',
    id: 210,
  },
  {
    country_name: 'South Sudan',
    country_code: 'SS',
    id: 211,
  },
  {
    country_name: 'Spain',
    country_code: 'ES',
    id: 212,
  },
  {
    country_name: 'Sri Lanka',
    country_code: 'LK',
    id: 213,
  },
  {
    country_name: 'Sudan',
    country_code: 'SD',
    id: 214,
  },
  {
    country_name: 'Suriname',
    country_code: 'SR',
    id: 215,
  },
  {
    country_name: 'Svalbard and Jan Mayen',
    country_code: 'SJ',
    id: 216,
  },
  {
    country_name: 'Swaziland',
    country_code: 'SZ',
    id: 217,
  },
  {
    country_name: 'Sweden',
    country_code: 'SE',
    id: 218,
  },
  {
    country_name: 'Switzerland',
    country_code: 'CH',
    id: 219,
  },
  {
    country_name: 'Syrian Arab Republic',
    country_code: 'SY',
    id: 220,
  },
  {
    country_name: 'Taiwan, Province of China',
    country_code: 'TW',
    id: 221,
  },
  {
    country_name: 'Tajikistan',
    country_code: 'TJ',
    id: 222,
  },
  {
    country_name: 'Tanzania, United Republic of',
    country_code: 'TZ',
    id: 223,
  },
  {
    country_name: 'Thailand',
    country_code: 'TH',
    id: 224,
  },
  {
    country_name: 'Timor-Leste',
    country_code: 'TL',
    id: 225,
  },
  {
    country_name: 'Togo',
    country_code: 'TG',
    id: 226,
  },
  {
    country_name: 'Tokelau',
    country_code: 'TK',
    id: 227,
  },
  {
    country_name: 'Tonga',
    country_code: 'TO',
    id: 228,
  },
  {
    country_name: 'Trinidad and Tobago',
    country_code: 'TT',
    id: 229,
  },
  {
    country_name: 'Tunisia',
    country_code: 'TN',
    id: 230,
  },
  {
    country_name: 'Turkey',
    country_code: 'TR',
    id: 231,
  },
  {
    country_name: 'Turkmenistan',
    country_code: 'TM',
    id: 232,
  },
  {
    country_name: 'Turks and Caicos Islands',
    country_code: 'TC',
    id: 233,
  },
  {
    country_name: 'Tuvalu',
    country_code: 'TV',
    id: 234,
  },
  {
    country_name: 'Uganda',
    country_code: 'UG',
    id: 235,
  },
  {
    country_name: 'Ukraine',
    country_code: 'UA',
    id: 236,
  },
  {
    country_name: 'United Arab Emirates',
    country_code: 'AE',
    id: 237,
  },
  {
    country_name: 'United Kingdom',
    country_code: 'GB',
    id: 238,
  },
  {
    country_name: 'United States',
    country_code: 'US',
    id: 239,
  },
  {
    country_name: 'United States Minor Outlying Islands',
    country_code: 'UM',
    id: 240,
  },
  {
    country_name: 'Uruguay',
    country_code: 'UY',
    id: 241,
  },
  {
    country_name: 'Uzbekistan',
    country_code: 'UZ',
    id: 242,
  },
  {
    country_name: 'Vanuatu',
    country_code: 'VU',
    id: 243,
  },
  {
    country_name: 'Venezuela',
    country_code: 'VE',
    id: 244,
  },
  {
    country_name: 'Viet Nam',
    country_code: 'VN',
    id: 245,
  },
  {
    country_name: 'Virgin Islands, British',
    country_code: 'VG',
    id: 246,
  },
  {
    country_name: 'Virgin Islands, U.s.',
    country_code: 'VI',
    id: 247,
  },
  {
    country_name: 'Wallis and Futuna',
    country_code: 'WF',
    id: 248,
  },
  {
    country_name: 'Western Sahara',
    country_code: 'EH',
    id: 249,
  },
  {
    country_name: 'Yemen',
    country_code: 'YE',
    id: 250,
  },
  {
    country_name: 'Zambia',
    country_code: 'ZM',
    id: 251,
  },
  {
    country_name: 'Zimbabwe',
    country_code: 'ZW',
    id: 252,
  },
];

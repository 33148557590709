var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('overlay-scrollbars',{ref:"osRef",class:("custom-scroll " + _vm.className),attrs:{"options":{
    scrollbars: { autoHide: _vm.autoHide, clickScrolling: true },
    overflowBehavior: {
      x: _vm.overflowX,
      y: _vm.overflowY,
    },
    resize: 'none',
    sizeAutoCapable: false,
    callbacks: {
      onOverflowAmountChanged: function (e) { return _vm.$emit('overflowAmountChanged', { e: e, instance: _vm.$refs.osRef.osInstance() }); },
    },
  }}},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-menu
    transition="slide-y-transition"
    dark
    offset-y
    bottom
    content-class="downloadMenu"
    :close-on-content-click="false"
  >
    <template #activator="menu">
      <v-btn
        text
        v-on="menu.on"
        id="download"
        v-bind="{ ...menu.attrs }"
        @click="downloadButtonClick"
        :disabled="inserting || noOutputs || loading || uploading"
      >
        {{ GEN_DOWNLOAD }}
        <v-icon right>mdi-chevron-down</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item @click="downloadWord">
        <v-list-item-icon>
          <img src="@/data/static/WordDownload.svg" alt="Word icon" class="wordImage" />
        </v-list-item-icon>
        <v-list-item-title>{{ GEN_DESCRIPTION }}</v-list-item-title>
      </v-list-item>
      <v-list-item key="1" @click="downloadVisio">
        <v-list-item-icon>
          <img src="@/data/static/Visio Logo.png" alt="Visio Logo" class="image" />
        </v-list-item-icon>
        <v-list-item-title v-text="`${GEN_DIAGRAM}s (Visio)`" />
      </v-list-item>
      <v-list-item @click="downloadPowerPoint">
        <v-list-item-icon>
          <img src="@/data/static/PowerPointLogo.svg" alt="Word icon" class="wordImage" />
        </v-list-item-icon>
        <v-list-item-title v-text="`${GEN_DIAGRAM}s (PPT)`" />
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import {
  GEN_DOWNLOAD,
  GEN_DOCUMENT,
  GEN_DESCRIPTION,
  GEN_DIAGRAM,
} from '@/data/constants/constantsMessages';
import { mapState } from 'vuex';
import { renderDoc } from '@/support/description/docxTemplater';
import { UploadKeys as uk, DraftKeys as dk } from '@/data/constants';
import {
  ACTION_CLICK,
  ACTION_DOWNLOAD_VISIO,
  ACTION_DOWNLOAD_WORD,
  CATEGORY_DOWNLOAD,
  CATEGORY_DOWNLOAD_MENU_BUTTON,
} from '@/data/constants/constantsGoogleAnalyticsEvents';
import { DOWNLOAD_BUTTON_MESSAGE } from '@/data/constants/constantsTooltip';
import { exportVsdx } from '@/support/diagram';
import exportPowerPointFile from '@/support/diagram/PowerPointExport';

export default {
  name: 'DownloadMenu',
  data() {
    return {
      GEN_DOWNLOAD,
      GEN_DOCUMENT,
      GEN_DESCRIPTION,
      GEN_DIAGRAM,
      DOWNLOAD_BUTTON_MESSAGE,
    };
  },

  methods: {
    downloadWord() {
      renderDoc(this.description);
      this.$gtag.event(ACTION_DOWNLOAD_WORD, { event_category: CATEGORY_DOWNLOAD });
    },

    downloadVisio() {
      try {
        const pages = this.getGraphPages();
        const title = this.description.split('</h1>')[0].replace('<h1>', '');
        exportVsdx(pages, title);
      } catch (err) {
        console.error(err);
        this.$store.commit('error/setError', true);
      } finally {
        this.$emit('diagramDownloaded');
        this.$gtag.event(ACTION_DOWNLOAD_VISIO, { event_category: CATEGORY_DOWNLOAD });
      }
    },

    downloadPowerPoint() {
      const pages = this.getGraphPages();
      const title = this.description.split('</h1>')[0].replace('<h1>', '');
      exportPowerPointFile(pages, title, this.$store.state.draft.activeProfile.truncateText);
    },

    getGraphPages() {
      return this.$parent.$parent.$parent.$parent.$refs.workspace.$refs.diagramEditors.map((d) => {
        return {
          graph: d.graph,
          illustration: d.store.illustration,
        };
      });
    },

    downloadButtonClick() {
      this.$gtag.event(ACTION_CLICK, { event_category: CATEGORY_DOWNLOAD_MENU_BUTTON });
    },
  },

  computed: {
    ...mapState({
      inserting: (state) => state.draft[dk.INSERTING],
      diagrams: (state) => state.draft[dk.DIAGRAMS],
      description: (state) => state.draft[dk.DESCRIPTION],
      loading: (state) => state.draft[dk.LOADING],
      uploading: (state) => state[uk.MODULE_NAME][uk.UPLOADING],
    }),

    noOutputs() {
      if (this.diagrams.length === 0 && this.diagrams.length === 0) {
        return true;
      }

      if (this.diagrams.length === 1) {
        if (
          this.diagrams[0].blocks.length === 0 &&
          this.description.replace(/[</?p>]/gi, '').length === 0
        ) {
          return true;
        }
      }

      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
#download {
  align-self: center !important;
}

.image {
  width: 30px;
  height: 30px;
}
.wordImage {
  width: 32px;
  height: 32px;
}
</style>

// Example claims used in the claims editor

// Simple system claims
export const SIMPLE_SYSTEM = `1. A system, comprising:
a first device, 
a second device, comprising:
\ta first module; and
\ta second module; and
a third device.
2. The system of claim 1, wherein the first device is a processor.
3. The system of claim 1, wherein the second device further comprises:
a third module; and
a fourth module.
4. The system of claim 1, further comprising:
a fourth device.`;

export const SIMPLE_METHOD = `1. A method comprising:
receiving a signal;
processing the signal;
analyzing the signal; and
transmitting the signal.`;

export const COMPLEX_METHOD = `1. A method for capturing body motion associated with a person pretending to hit or launch an object, comprising:
determining positions of at least one point on a body of the person during a plurality of instances of the person attempting to repeat the body motion;
determining a value indicative of an amount of variation in the positions of the at least one point during the plurality of instances;
determining, based on the value, a motion consistency parameter indicative of a consistency of the body motion during the plurality of instances;
receiving the motion consistency parameter at a processor;
receiving, at the processor, sensor interface data from a sensor system, the sensor interface data indicative of movement of the person during a single instance of the person attempting to repeat the body motion;
based on the sensor interface data, predicting by the processor a magnitude of a force and a direction of the force that would be imparted to a simulated object if it was actually hit or launched;
determining by the processor whether the desired outcome or a non-desired outcome is to occur for the simulated object based on the predicted magnitude and the predicted direction of the force and based on the motion consistency parameter, wherein a probability of the desired outcome occurring is greater when the value indicates a smaller amount of variation in the positions of the at least one point; and
controlling by the processor output to a display including one or more images of a simulated trajectory of the simulated object, wherein the simulated trajectory shows the determined desired outcome or the determined non-desired outcome occurring for the simulated object.`;

// Example system claims: seismic anchor
export const COMPLEX_SYSTEM = `CLAIMS
1. 	A vehicle power-assist drive system, comprising:
a drive unit assembly configured to be attached to a frame of a vehicle, the drive unit assembly including:
	a motor including an output shaft; and
	a drive gear rotatably associated with the output shaft; and
a driven unit assembly including:
	a driven gear configured to be attached to a wheel hub of the vehicle; and
	a brake disc coaxially attached to the driven gear;
wherein the drive gear is configured to operably engage the driven gear to transmit power from the motor to the wheel hub.
2. 	The vehicle power-assist drive system of claim 1, wherein the drive unit assembly further comprises a brake caliper, wherein the brake caliper is configured to frictionally interface with the brake disc to counteract rotational motion of the wheel hub.
3.	The vehicle power-assist drive system of claim 1, wherein the drive unit assembly further comprises:
a pinion gear coupled to the output shaft of the motor; and
a reduction gearset positioned between the pinion gear and the drive gear, wherein the reduction gearset is configured to reduce the rotational speed of the drive gear relative to the rotational speed of the output shaft.
4.	The vehicle power-assist drive system of claim 3, wherein the drive unit assembly further comprises a clutch configured to selectively couple the pinion gear to the drive gear.
5.	The vehicle power-assist drive system of claim 4, further comprising a freewheel gear positioned between the first reduction gearset and coupled to the output gear by a driveshaft, the freewheel gear including the clutch.
6.	The vehicle power-assist drive system of claim 4, further comprising a user-selectable control configured to actuate the clutch to selectively couple the pinion gear to the drive gear.
7.	The vehicle power-assist drive system of claim 1, wherein the drive unit assembly includes a bracket configured to be fixedly attached to the frame, wherein the brake disc is arranged outboard of the driven gear.
8.	The vehicle power-assist drive system of claim 2, wherein the drive unit assembly includes a bracket configured to be fixedly attached to the frame, wherein the brake caliper is arranged between the motor and the bracket.
9.	The vehicle power-assist drive system of claim 1, wherein the drive unit further includes:
a quick-connect assembly threadably coupled to the drive unit, the quick-connect assembly including a cam lever pivotally attached to a shaft extending coaxially through a body, the cam lever having an eccentric cam surface configured to axially translate the shaft upon rotation of the cam lever, wherein translation of the shaft causes radial translation of a locking ball adjacent to the shaft; and
a quick-connect coupling threadably coupled to the frame, the quick-connect coupling including a bore having a locking cavity and configured to receive the body of the quick-connect assembly therein,
wherein outward radial translation of the locking ball in response to axial translation of the shaft moves the locking ball into engagement with the locking cavity to prevent removal of the shaft from the bore when the cam lever is in a first position.
10.	The vehicle power-assist drive system of claim 1, wherein the brake disc and the driven gear are a unitary component.
11.	The vehicle power-assist drive system of claim 1, wherein the brake disc is removably coupled to the driven gear.
12.	The vehicle power-assist drive system of claim 1, further comprising:
a battery electrically coupled to the motor; and
a speed controller in electrical communication with the battery and the motor, wherein the speed controller is configured to receive an input signal used to selectively direct electrical power to the motor.
13.	The vehicle power-assist drive system of claim 1, wherein the drive unit assembly is operably associated with at least one of a seat stay, a chain stay, or a front fork.
14. 	A power-assist drive system for use with a bicycle, comprising:
a drive unit assembly configured to be removably coupled to the bicycle, the drive unit assembly including:
	a motor including an output shaft;
	a drive gear rotationally associated with the output shaft; and
	a brake caliper;
a battery electrically connectable to the motor and a speed controller;
a driven unit assembly configured to be operably coupled to a wheel of the bicycle, the driven unit assembly including:
	a driven gear fixedly attachable to a hub of the wheel and configured to operably engage the drive gear; and
	a brake disc coaxially attached to the driven gear, the brake disc including a friction surface configured to be movably received by the brake caliper,
wherein the speed controller is configured to receive an input signal to selectively direct electrical power from the battery to the motor to drive the wheel via the drive gear and the driven gear.
15.	The vehicle power-assist drive system of claim 14, wherein the drive unit assembly is configured to be removably couplable to at least one of a frame of the bicycle or a fork of the bicycle.
16.	The vehicle power-assist drive system of claim 15, wherein the drive unit assembly is configured to be operably coupled to a seat stay or a chain stay of the frame.
17.	The vehicle power-assist drive system of claim 14, wherein:
the driven unit assembly is attached to a rear wheel of the bicycle; and
the drive unit assembly is configured to drive the rear wheel.
18.	The vehicle power-assist drive system of claim 14, wherein:
the drive unit assembly is removably couplable to a stanchion of a fork attached to a frame of the bicycle; and
the wheel is a front wheel of the bicycle.
19.	The vehicle power-assist drive system of claim 14, wherein:
the drive unit assembly is a first drive unit assembly configured to be removably coupled to a seat stay or a chain stay of a frame of the bicycle;
the driven unit assembly is a first driven unit assembly configured to be operably coupled to a rear wheel of the bicycle;
the bicycle further includes:
	a second drive unit assembly configured to be removably coupled to a fork attached to the frame; and
	a second driven unit assembly configured to be operably coupled to a hub of a front wheel of the bicycle; and
the speed controller is configured to receive an input signal to selectively direct electrical power from the battery to drive the rear wheel via the first drive unit assembly and drive the front wheel via the second drive unit assembly.
20.	The vehicle power-assist drive system of claim 19, wherein the rear wheel and the front wheel are driven simultaneously.
21.	The vehicle power-assist drive system of claim 19, wherein the rear wheel and the front wheel are driven simultaneously, and the rear wheel is driven with a greater power than the front wheel.
22.	The vehicle power-assist drive system of claim 19, wherein the rear wheel and the front wheel are driven independently.
23.	The vehicle power-assist drive system of claim 14, wherein the brake caliper carries one or more pads configured to frictionally interface with the friction surface of the brake disc to counteract rotational motion of the wheel.
24.	A power-assisted vehicle, comprising:
a frame;
a wheel rotatably supporting the frame;
an electric motor operably mounted to the frame;
a drive gear operably coupled to the electric motor;
a brake caliper operably coupled to the frame;
a driven gear fixedly attached to a hub of the wheel and operably engaged with the drive gear; and
a brake disc positioned coaxially adjacent to the driven gear and configured to frictionally interface with the brake caliper,
wherein operation of the electric motor rotationally drives the wheel via the transmission of power from the drive gear to the driven gear.
25.	The power-assisted vehicle of claim 24, further comprising:
a battery electrically connected to the motor; and
a speed controller in electrical communication with at least one of the battery and the motor,
wherein the speed controller is configured to receive an input signal from a user and variably direct electrical power to the motor.
26.	The power-assisted vehicle of claim 24, wherein the brake disc is integral to the driven gear.
27.	The power-assisted vehicle of claim 24, wherein the brake disc is fixedly attached to the driven gear.
28.	The power-assisted vehicle of claim 24, wherein the brake disc and the driven gear are formed from a single piece of material
29.	The power-assisted vehicle of claim 24, wherein the frame includes a front fork and the wheel is rotatably mounted to the front fork, and wherein the electric motor, the drive gear, and the brake caliper are mounted to the front fork.
30.	The power-assisted vehicle of claim 24, wherein the frame includes a seat stay, and wherein the electric motor, the drive gear, and the brake caliper are mounted to the seat stay.
31.	The power-assisted vehicle of claim 24, wherein the electric motor is mounted to a housing and the brake caliper is coupled to the housing.
32.	The power-assisted vehicle of claim 31, wherein the drive gear is rotatably coupled to the housing.
33.	The power-assisted vehicle of claim 24, wherein the frame includes an integral housing, and wherein the electric motor is mounted to the housing.`;

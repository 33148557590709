import { UploadKeys as K } from '@/data/constants';
import { selectFile } from '@/support/utilities';
import { CATEGORY_UPLOAD } from '@/data/constants/constantsGoogleAnalyticsEvents';

export default {
  /**
   * Generic state mutation for upload module
   *
   * @param {Object} state - Vuex state module
   * @param {String} key - String representing the state attribute to update
   * @param val - Value to set
   */
  setUploadState(state, { key, val }) {
    state[key] = val;
  },

  toggleDialog(state, payload = { val: !state[K.DIALOG], overwrite: false }) {
    state[K.DROP] = payload.val;
    state[K.DIALOG] = payload.val;
    state[K.OVERWRITE] = payload.overwrite;
  },

  triggerSelection(state, cb) {
    selectFile(cb, '.docx');
    state[K.OVERWRITE] = false;
  },

  saveFile(state, { e, action }) {
    this.$app.$gtag.event(action, { event_category: CATEGORY_UPLOAD });
    e.preventDefault();
    state[K.DROP] = true;
    state[K.FILE] = e.target.files ? e.target.files[0] : e.dataTransfer.files[0];
    if (e.target.files) e.target.remove();
  },

  startUpload(state) {
    state[K.UPLOADING] = true;
  },

  endUpload(state) {
    state[K.UPLOADING] = false;
    state[K.DROP] = false;
    state[K.FILE] = null;
    state[K.PROGRESS] = 0;
  },
};

import { ErrorKeys as K, messages as M } from '@/data/constants';

const state = {
  [K.ERROR]: false,
  [K.WARN]: false,
  [K.SUCCESS]: false,
  [K.MESSAGE]: M.ERROR_APP,
};

export default state;

import { v4 as uuid } from 'uuid';

/**
 * @memberOf Internal
 * @property {String} id - UUID string
 * @property {String} text - Concept text
 * @property {String[]} tags - Array of UUID strings of tags referring to this concept
 * @property {String[]} links - Array of UUID string of links connected to this concept
 * @property {String[]} children - Array of UUID strings of child concepts
 */
export default class Concept {
  constructor(data) {
    this.id = data.id || uuid();
    this.tags = data.refs || [];
    this.anchor = data.anchor || null;
    this.outline = data.outline;
    this.lead = null;
  }
}

// These are the default phrases in phrase customizations of the customization overlay

export const include = 'include';
export const including = 'including';
export const may_be = 'may be';
export const may_include = `may ${include}`;
export const may_also_include = `may also ${include}`;

export const disclosure = 'disclosure';
export const embodiments = 'embodiments';
export const in_some_embodiments = `in some ${embodiments}`;
export const embodiments_of_disclosure = `${embodiments} of the present ${disclosure}`;
export const according_to_embodiments = `according to some ${embodiments_of_disclosure}`;
export const embodiments_may_include = `${embodiments} ${may_include}`;
export const embodiments_may_also_include = `${embodiments} ${may_also_include}`;

export const summary_intro = `${embodiments_of_disclosure} ${may_include}`;
export const family_intro = `${embodiments_of_disclosure} ${may_also_include}`;
export const concept_intro = `${embodiments_may_also_include}`;
export const context_intro = `${in_some_embodiments}`;

<template>
  <div id="signup-layout__root">
    <div class="d-flex align-center justify-space-between pa-2 top">
      <div>
        <v-img src="@/data/static/Logo.png" width="200" height="50" class="mx-4 mt-2" />
      </div>
    </div>
    <div class="d-flex flex-row body">
      <div class="splash">
        <div class="splash-image-container">
          <img src="@/data/static/Splash.png" alt="Visio Logo" class="splash-image" />
        </div>
      </div>
      <main>
        <slot />
      </main>
    </div>
  </div>
</template>

<script>
import { GEN_LOG_OUT } from '@/data/constants/constantsMessages';

export default {
  name: 'SignupLayout',
  data() {
    return {
      GEN_LOG_OUT,
    };
  },
};
</script>

<style lang="scss">
$page-height: calc(100vh - 74px);
$min-splash-height: 600px;

.scroll-container {
  width: 100%;
}

#signup-layout__root {
  height: fit-content;
  min-height: 100vh;
  .splash-image {
    position: fixed;
    max-width: 50%;
    min-width: 600px;
    min-height: 853.33px;
    max-height: 100%;
    @media screen and (min-height: 853.33px) {
      top: 50%;
      transform: translate(0, -50%);
    }
  }

  .splash-image-container {
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    min-width: 600px;
    width: inherit;
    max-width: inherit;
    height: 100vh;
  }

  .top {
    pointer-events: none;
    position: fixed;
    @media screen and (max-width: 750px) {
      position: absolute;
    }
  }

  .splash {
    pointer-events: none;
    position: relative;
    width: 100%;
    min-height: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    overflow-x: hidden;
    overflow-y: visible;
    direction: rtl;
    left: 0;

    @media screen and (max-width: 1335px) {
      direction: rtl;
    }

    @media screen and (max-width: 700px) {
      display: none;
    }

    @media screen and (max-height: 853.33px) {
      justify-content: initial;
    }
  }

  .body {
    min-height: 100vh;
  }

  main {
    min-height: 100vh;
    height: fit-content;
    width: 100%;
    padding: 75px 75px 0;
    display: flex;
    justify-content: center !important;
    @media screen and (max-width: 700px) {
      padding: 75px 12px 0;
    }
  }

  #signup-layout__root {
    min-height: 100vh;
    width: 100%;
  }

  div#stripe-element-mount-point.StripeElement {
    box-sizing: border-box;
    transition: border-color 0.3s;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 1px 5px 0 rgba(0, 0, 0, 0.12);
    &.StripeElement--invalid {
      border: 1px solid #eb1c26;
    }
  }

  div#stripe-element-errors {
    color: red;
  }

  .card-container {
    .v-text-field {
      &:nth-child(1) {
        margin-right: 10px;
        width: 300px;
      }
      &:nth-child(2) {
        margin-left: 10px;
        margin-right: 10px;
        width: 80px;
      }
      &:nth-child(3) {
        margin-left: 10px;
        width: 60px;
      }
    }
  }

  .input-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .v-input {
      width: 240px;
      max-width: 100%;
      &:nth-child(1) {
        margin-right: 10px;
      }
      &:nth-child(2) {
        margin-left: 10px;
      }
    }
  }

  .v-form {
    section {
      width: 100%;
    }
  }

  .v-input--checkbox {
    label.v-label {
      font-size: 12px;
    }
  }

  #subscribe {
    &.container {
      max-width: 380px !important;
      min-width: 380px !important;
    }
  }

  #login,
  #signup {
    &.container {
      max-width: 450px !important;
      min-width: 450px !important;
    }
  }

  #login,
  #signup,
  #subscribe {
    &.container {
      position: relative;
      padding: 0 0 75px 0 !important;
      margin: 0 !important;
      @media screen and (max-width: 700px) {
        min-width: initial !important;
      }
    }

    .name-container {
      display: flex;
      width: 100%;

      .v-text-field {
        &:nth-child(1) {
          margin-right: 15px;
        }

        &:nth-child(2) {
          margin-left: 15px;
        }
      }
    }

    .v-btn {
      font-size: 18px !important;
      font-weight: bold;
    }

    .v-text-field {
      width: 100% !important;
    }

    .caption {
      a {
        color: inherit;
        text-decoration: underline;
      }
    }

    form {
      width: 100%;
    }
  }
}
</style>
